const ATRIUM = [  
  {
    "id": "0-atrium",
    "name": "Empfangshalle",
    "hasChildren": true,
    "hasMenu": true,
    "parent": "",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": -1.4029295326341007,
      "pitch": 0.13064140715332329,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": -1.3515857476355428,
        "pitch": 0.146531757683789,
        "rotation": 0,
        "target": "1-empfang--anmeldung"
      },
      {
        "yaw": -2.0442627780006113,
        "pitch": 0.1557774391490021,
        "rotation": 0,
        "target": "2--institut-fr-neue-energie-systeme-ines"
      },
      {
        "yaw": -2.9083927194272885,
        "pitch": 0.13748275281141886,
        "rotation": 0,
        "target": "3-studiengang",
        isRoom: true
      },
      {
        "yaw": 2.934138328400034,
        "pitch": 0.14665948470508638,
        "rotation": 0,
        "target": "4-poster-tav-control",
        isRoom: true
      },
      {
        "yaw": 2.405262910367764,
        "pitch": 0.08644133602578563,
        "rotation": 0,
        "target": "5-poster-flexbiomethane",
        isRoom: true
      },
      {
        "yaw": 2.1620983128365614,
        "pitch": 0.07663922093641062,
        "rotation": 0,
        "target": "5-waermenetzprojekte",
        isRoom: true
      },
     /* {
        "yaw": -2.5203274769452566,
        "pitch": 0.0356635083336343,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -2.5203274769452566,
        "pitch": -0.0356635083336343,
        "rotation": 0,
        "target": "0-lobby"
      }, 
      {
        "yaw": -0.7222341306602011,
        "pitch": 0.040643885024724824,
        "rotation": 0,
        "target": "6-wrmetechnischer-prfstand"
      },
      {
        "yaw": 2.6860265797150067,
        "pitch": -0.10122326051267905,
        "rotation": 0,
        "target": "7-biogas-anlage"
      },*/
      {
        "yaw": -0.05675374787907117,
        "pitch": 0.13722695731017254,
        "rotation": 0,
        "target": "12-varmeco",
        isRoom: true
      },
      {
        "yaw": 0.292709140104467,
        "pitch": 0.12003345997263537,
        "rotation": 0,
        "target": "11-enerpipe",
        isRoom: true
      },
      {
        "yaw": 0.6136716565921976,
        "pitch": 0.11642147239405531,
        "rotation": 0,
        "target": "11-ratiotherm",
        isRoom: true
      },
      {
        "yaw": 0.953869413009631,
        "pitch": 0.11788647255533213,
        "rotation": 0,
        "target": "10-schwaben-regenerativ",
        isRoom: true
      },
      {
        "yaw": 1.241678152213849,
        "pitch": 0.10077902430235142,
        "rotation": 0,
        "target": "9-lew-lechwerke",
        isRoom: true
      },
      {
        "yaw": 1.4735969968300848,
        "pitch": 0.08407671504251368,
        "rotation": 0,
        "target": "9-carmen",
        isRoom: true
      },
      {
        "yaw": 1.693234307032558,
        "pitch": 0.06747559112834445,
        "rotation": 0,
        "target": "8-omnicert",
        isRoom: true

      },
      {
        "yaw": 1.927114277373378,
        "pitch": 0.07225963501495158,
        "rotation": 0,
        "target": "8-grammersolar",
        isRoom: true
      }
    ],
    "embeddedHotspots": [
      {
        type: "video", 
        source: "/material/rollups/biogas-video.mp4",
        yaw: -1,
        pitch: 0,
        radius: 1500,
        translateX: -430,
        translateY: -600,
      }
    ],
    "infoHotspots": [
      {
        yaw: 5.1,
        pitch: -0.1,
        translateX: "-500px",
        translateY: "220px",
        radius: 1050,
        action: "showImageBox",
        type: "bobbel",
        params: {
          slides: [
            "/material/rollups/Biogas40.png"
          ],
          pdf: "/material/rollups/Biogas40.pdf",
        },
        title: "Biogas 4.0 Roll-Up",
        showInNavbar: true,
      },
      {
        yaw: 5.68,
        pitch: -0.06,
        translateX: "-500px",
        translateY: "220px",
        radius: 1050,
        action: "showImageBox",
        type: "bobbel",
        params: {
          slides: [
            "/material/rollups/InES.png"
          ],
          pdf: "/material/rollups/InES.pdf",
        },
        title: "InES Roll-Up",
        showInNavbar: true,
      },
      {
        yaw: 4.45,
        pitch: -0.1,
        translateX: "-500px",
        translateY: "220px",
        radius: 1050,
        action: "showImageBox",
        type: "bobbel",
        params: {
          slides: [
            "/material/rollups/lenz.jpg"
          ],
          pdf: "/material/rollups/lenz.pdf",
        },
        title: "LEnZ Roll-Up",
        showInNavbar: true,
      },
      {
        yaw: 6.25,
        pitch: -0.1,
        translateX: "-500px",
        translateY: "220px",
        radius: 1050,
        action: "showImageBox",
        type: "bobbel",
        params: {
          slides: [
            "/material/rollups/WaermeWohnen.png"
          ],
          pdf: "/material/rollups/WaermeWohnen.pdf",
        },
        title: "Wärme & Wohnen Roll-Up",
        showInNavbar: true,
      }
    ]
  },
  {
    "id": "1-empfang--anmeldung",
    "name": "Empfang",
    "parent": "0-atrium",
    hasChildren: true,
    hasMenu: true,
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 0.802339381630854,
      "pitch": 0.2950446120155827,
      "fov": 2
    },
    "linkHotspots": [
      {
        "yaw": -1.325152002474617,
        "pitch": 0.19946488217736302,
        "rotation": 0,
        "target": "2--institut-fr-neue-energie-systeme-ines"
      },
     /* {
        "yaw": -1.230436495526586,
        "pitch": -0.08682778445878995,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -1.230436495526586,
        "pitch": -0.01682778445878995,
        "rotation": 0,
        "target": "0-lobby"
      }, */
      {
        "yaw": -2.264650098354327,
        "pitch": -0.024555860653285393,
        "rotation": 0,
        "target": "4-poster-tav-control",
        isRoom: true
      },
      {
        "yaw": -2.0809538050075214,
        "pitch": -0.02001548146994956312,
        "rotation": 0,
        "target": "3-studiengang",
        isRoom: true
      },
      {
        "yaw": -2.408668849402183,
        "pitch": -0.000373475762434978,
        "rotation": 0,
        "target": "5-waermenetzprojekte",
        isRoom: true
      },
      {
        "yaw": -2.5389279093367387,
        "pitch": -0.0458706399587507,
        "rotation": 0,
        "target": "5-poster-flexbiomethane",
        isRoom: true
      },
     /* {
        "yaw": 1.9580526432154253,
        "pitch": -0.009277102080348598,
        "rotation": 0,
        "target": "7-biogas-anlage"
      }, */
      {
        "yaw": -2.6757150623898664,
        "pitch": -0.02979567715045816,
        "rotation": 0,
        "target": "8-grammersolar",
        isRoom: true
      },
      {
        "yaw": -2.8216669529475726,
        "pitch": -0.030453135249662637,
        "rotation": 0,
        "target": "8-omnicert",
        isRoom: true
      },
      {
        "yaw": -2.943640895814197,
        "pitch": -0.005752887682623395,
        "rotation": 0,
        "target": "9-carmen",
        isRoom: true
      },
      {
        "yaw": -3.056466463168903,
        "pitch": -0.042163251290923995,
        "rotation": 0,
        "target": "9-lew-lechwerke",
        isRoom: true
      },
      {
        "yaw": 3.094213179470437,
        "pitch": -0.00274288201372883,
        "rotation": 0,
        "target": "10-schwaben-regenerativ",
        isRoom: true
      },
      {
        "yaw": 2.925965349691309,
        "pitch": -0.0220397426828537846,
        "rotation": 0,
        "target": "11-ratiotherm",
        isRoom: true
      },
      {
        "yaw": 2.771695649256099,
        "pitch": 0.009894343353906265,
        "rotation": 0,
        "target": "11-enerpipe",
        isRoom: true
      },
      {
        "yaw": 2.5949106628934953,
        "pitch": -0.0109600852931937,
        "rotation": 0,
        "target": "12-varmeco",
        isRoom: true
      }
    ],
    "infoHotspots": [
      {
        yaw: .2,
        pitch: 0,
        translateX: "-500px",
        translateY: "220px",
        radius: 1050,
        action: "showImageBox",
        thumb: "/material/empfang/bg40power/x_Seite_1.jpg",
        params: {
          slides: [
            "/material/empfang/bg40power/x_Seite_1.jpg",
            "/material/empfang/bg40power/x_Seite_2.jpg",
            "/material/empfang/bg40power/x_Seite_3.jpg",
            "/material/empfang/bg40power/x_Seite_4.jpg",
            "/material/empfang/bg40power/x_Seite_5.jpg",
            "/material/empfang/bg40power/x_Seite_6.jpg",
            "/material/empfang/bg40power/x_Seite_7.jpg",
            "/material/empfang/bg40power/x_Seite_8.jpg"
          ],
          pdf: "/material/empfang/bg40power/BG40_Informationsblatt_Repowering_Flexibilisierung.pdf",
        },
        title: "Repowering und Flexibilisierung (aus dem Vorgängerprojekt Biogas 4.0)",
        showInNavbar: true,
      }, {
        yaw: 1,
        pitch: -.1,
        radius: 800,
        translateX: "140px",
        translateY: "470px",
        action: "showImageBox",
        thumb: "/material/empfang/biogasnacheeg/x_Seite_01.jpg",
        params: {
          slides: [
            "/material/empfang/biogasnacheeg/x_Seite_01.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_02.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_03.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_04.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_05.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_06.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_07.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_08.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_09.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_10.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_11.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_12.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_13.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_14.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_15.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_16.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_17.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_18.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_19.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_20.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_21.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_22.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_23.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_24.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_25.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_26.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_27.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_28.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_29.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_30.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_31.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_32.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_33.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_34.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_35.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_36.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_37.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_38.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_39.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_40.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_41.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_42.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_43.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_44.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_45.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_46.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_47.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_48.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_49.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_50.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_51.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_52.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_53.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_54.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_55.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_56.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_57.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_58.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_59.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_60.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_61.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_62.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_63.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_64.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_65.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_66.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_67.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_68.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_69.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_70.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_71.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_72.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_73.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_74.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_75.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_76.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_77.jpg",
            "/material/empfang/biogasnacheeg/x_Seite_78.jpg",
          ],
          pdf: "/material/empfang/biogasnacheeg/REzAB_Biogas_nach_dem_EEG_Broschuere.pdf",
        },
        title: "Biogas nach EEG",
        showInNavbar: true,
       }, {
        yaw: 1.2,
        pitch: 0,
        translateX: "400px",
        translateY: "220px",
        radius: 1050,
        action: "showImageBox",
        thumb: "/material/empfang/ww_leitfaden/x_Seite_01.jpg",
        params: {
          slides: [
            "/material/empfang/ww_leitfaden/x_Seite_01.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_02.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_03.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_04.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_05.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_06.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_07.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_08.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_09.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_10.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_11.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_12.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_13.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_14.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_15.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_16.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_17.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_18.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_19.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_20.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_21.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_22.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_23.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_24.jpg",
            "/material/empfang/ww_leitfaden/x_Seite_25.jpg",
          ],
          pdf: "/material/empfang/ww_leitfaden/WaermeWohnen_Leitfaden_zur_Sanierung_von_Waermenetze.pdf",
        },
        title: "W&W Leitfaden (aus dem Vorgängerprojekt Wärme&Wohnen)",
        showInNavbar: true,
      }, 
      {
        yaw: .45,
        pitch: 0,
        translateX: "-400px",
        translateY: "80px",
        radius: 850,
        action: "showImageBox",
        thumb: "/material/empfang/agenda/x_Seite_1.jpg",
        params: {
          slides: [
            "/material/empfang/agenda/x_Seite_1.jpg",
            "/material/empfang/agenda/x_Seite_2.jpg",
          ],
          pdf: "/material/empfang/agenda/Agenda.pdf",
        },
        title: "Agenda",
        showInNavbar: true,
      }, 
      {
        yaw: .6,
        pitch: 0,
        translateX: "-280px",
        translateY: "450px",
        radius: 850,
        action: "showImageBox",
        type: "quer",
        thumb: "/material/empfang/hybrid-wp/x_Seite_1.jpg",
      params: {
        type: "quer",
        slides: [
          "/material/empfang/hybrid-wp/x_Seite_1.jpg",
        ],
        pdf: "/material/empfang/hybrid-wp/20220704_LEnZ_HybridWP.pdf",
      },
      title: "Poster Hybrid-WP+",
        showInNavbar: true,
      }, 
      {
        yaw: .7,
        pitch: 0,
        translateX: "50px",
        translateY: "550px",
        radius: 850,
        action: "showImageBox",
        type: "small",
        thumb: "/material/empfang/flexlac/x_Seite_1.jpg",
        params: {
          slides: [
            "/material/empfang/flexlac/x_Seite_1.jpg"
          ],
          pdf: "/material/flexlac/agenda/flexLAC.pdf",
        },
        title: "Poster FlexLAC",
        showInNavbar: true,
      }
    ]
  },
  {
    "id": "2--institut-fr-neue-energie-systeme-ines",
    "parent": "0-atrium",
    "name": " Institut für neue Energie-Systeme (InES)",
    hasChildren: true,
    hasMenu: true,
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": -2.4251668917218517,
      "pitch": 0.30806026144045248,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
    /*  {
        "yaw": -0.05130304079029102,
        "pitch": -0.1551498731198908,
        "rotation": 0,
        "target": "7-biogas-anlage"
      },
      {
        "yaw": -2.89130304079029102,
        "pitch": -0.0551498731198908,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -2.89130304079029102,
        "pitch": -0.1251498731198908,
        "rotation": 0,
        "target": "0-lobby"
      }, */
      { 
        "yaw": -0.2947320469438992,
        "pitch": 0.1517160703649072,
        "rotation": 0,
        "target": "1-empfang--anmeldung"
      },
      {
        "yaw": 1.9213095293887504,
        "pitch": 0.024875602176709606,
        "rotation": 0,
        "target": "3-studiengang",
        isRoom: true
      },
      {
        "yaw": 0.470507846121133,
        "pitch": 0.008820927900808684,
        "rotation": 0,
        "target": "12-varmeco",
        isRoom: true
      },
      {
        "yaw": 0.640034759601803,
        "pitch": 0.006875057166793042,
        "rotation": 0,
        "target": "11-enerpipe",
        isRoom: true
      },
      {
        "yaw": 0.8123830065730533,
        "pitch": 0.001190364133526032,
        "rotation": 0,
        "target": "11-ratiotherm",
        isRoom: true
      },
      {
        "yaw": 0.9780553512614723,
        "pitch": -0.032958545674248256,
        "rotation": 0,
        "target": "10-schwaben-regenerativ",
        isRoom: true
      },
      {
        "yaw": 1.1284470108297646,
        "pitch": 0.004283004030977153,
        "rotation": 0,
        "target": "9-lew-lechwerke",
        isRoom: true
      },
      {
        "yaw": 1.269275344184416,
        "pitch": 0.003259213091565414,
        "rotation": 0,
        "target": "9-carmen",
        isRoom: true
      },
      {
        "yaw": 1.4062713532964817,
        "pitch": 0.000332156094907674,
        "rotation": 0,
        "target": "8-omnicert",
        isRoom: true
      },
      {
        "yaw": 1.5430039476540105,
        "pitch": 0.010540647720633345,
        "rotation": 0,
        "target": "8-grammersolar",
        isRoom: true
      },
      {
        "yaw": 1.7634478747400144,
        "pitch": 0.008193865900706143,
        "rotation": 0,
        "target": "5-waermenetzprojekte",
        isRoom: true
      },
      {
        "yaw": 1.661507333199045,
        "pitch": 0.048768824828042199,
        "rotation": 0,
        "target": "5-poster-flexbiomethane",
        isRoom: true
      }
    ],
    "infoHotspots": [
      {
        yaw: 4.5,
        pitch: 0, 
        translateX: "-100px",
        translateY: "60px",
        action: "openExternalLink",
        url: "https://www.thi.de/go/energie",
        params: {
          url: "https://www.thi.de/go/energie",
        },
        title: "www.thi.de/go/energie",
        showInNavbar: true,
      },
      {
        yaw: 3.9,
        pitch: 0,
        translateX: "-200px",
        translateY: "-100px",
        action: "openExternalLink",
        url: '#',
        params: {
          url: '#',
        },
        title: "<b>Ansprechpartner: Stefan Schneider</b><br /><small>Ich freue mich über Ihre Nachricht im Veranstaltungs-Chat!</small>",
        showInNavbar: true,
      }, 
      {
        yaw: 4.68,
        pitch: -.1,
        translateX: "-210px",
        translateY: "300px",
        radius: "720",
        action: "showImageBox",
        thumb: "/material/ines/forschungsmag/thumb.jpg",
        params: {
          slides: [
            "/material/ines/forschungsmag/x_Seite_01.jpg",
            "/material/ines/forschungsmag/x_Seite_02.jpg",
            "/material/ines/forschungsmag/x_Seite_03.jpg",
            "/material/ines/forschungsmag/x_Seite_04.jpg",
            "/material/ines/forschungsmag/x_Seite_05.jpg",
            "/material/ines/forschungsmag/x_Seite_06.jpg",
            "/material/ines/forschungsmag/x_Seite_07.jpg",
            "/material/ines/forschungsmag/x_Seite_08.jpg",
            "/material/ines/forschungsmag/x_Seite_09.jpg",
            "/material/ines/forschungsmag/x_Seite_10.jpg",
            "/material/ines/forschungsmag/x_Seite_11.jpg",
            "/material/ines/forschungsmag/x_Seite_12.jpg",
            "/material/ines/forschungsmag/x_Seite_13.jpg",
            "/material/ines/forschungsmag/x_Seite_14.jpg",
            "/material/ines/forschungsmag/x_Seite_15.jpg",
            "/material/ines/forschungsmag/x_Seite_16.jpg",
            "/material/ines/forschungsmag/x_Seite_17.jpg",
            "/material/ines/forschungsmag/x_Seite_18.jpg",
            "/material/ines/forschungsmag/x_Seite_19.jpg",
            "/material/ines/forschungsmag/x_Seite_20.jpg",
            "/material/ines/forschungsmag/x_Seite_21.jpg",
            "/material/ines/forschungsmag/x_Seite_22.jpg",
            "/material/ines/forschungsmag/x_Seite_23.jpg",
            "/material/ines/forschungsmag/x_Seite_24.jpg",
            "/material/ines/forschungsmag/x_Seite_25.jpg",
            "/material/ines/forschungsmag/x_Seite_26.jpg",
            "/material/ines/forschungsmag/x_Seite_27.jpg",
            "/material/ines/forschungsmag/x_Seite_28.jpg",
          ],
          pdf: "/material/ines/forschungsmag/Forschungsmag_InES_2019.pdf",
          type: "quer",
        },
        title: "Forschungsmagazin",
        showInNavbar: true,
       }, {
        yaw: 3.68,
        pitch: -.1,
        translateX: "-150px",
        translateY: "360px",
        action: "showImageBox",
        thumb: "/material/ines/flyer/x_Seite_1.jpg",
        params: {
          slides: [
            "/material/ines/flyer/x_Seite_1.jpg",
            "/material/ines/flyer/x_Seite_2.jpg",
          ],
          pdf: "/material/ines/flyer/InES_Flyer.pdf",
          type: "quer",
        },
        title: "Institutsflyer",
        showInNavbar: true,
       },
       {
        yaw: 4.1,
        pitch: .15,
        translateX: "-735px",
        translateY: "-100px",
        radius: 420,
        action: "showImageBox",
        thumb: "/material/ines/lenz/x_Seite_01.jpg",
        params: {
          slides: [
            "/material/ines/lenz/x_Seite_01.jpg"
          ],
          pdf: "/material/ines/lenz/20201201_Plakat_LEnZ_Fachtagung_BG4.0.pdf",
        },
        title: "Projekt LEnZ",
        showInNavbar: true,
       }, {
        yaw: 4.22,
        pitch: -.1,
        radius: 800,
        translateX: "-140px",
        translateY: "150px",
        action: "showImageBox",
        thumb: "/material/ines/grossflaechen/x_Seite_1.jpg",
        params: {
          slides: [
            "/material/ines/grossflaechen/x_Seite_1.jpg",
          ],
          pdf: "/material/ines/grossflaechen/Entwicklung_Grossflaechenkollektor_Isolierglasgbauweisee.pdf",
        },
        title: "Entwicklung Großflächenkollektor",
        showInNavbar: true,
       },
       {
        yaw: 3.68,
        pitch: -.1,
        translateX: "-400px",
        translateY: "400px",
        radius: 800,
        action: "showImageBox",
        thumb: "/material/210616_poster-tavcontrol/x_Seite_1.jpg",
        params: {
          slides: [
            "/material/210616_poster-tavcontrol/x_Seite_1.jpg",
          ],
          pdf: "/material/210616_poster-tavcontrol/050521_TAV_Control_Poster_KB.pdf",
        },
        title: "Thermischaktivierte Verkehrsfläche",
        showInNavbar: true,
       }
    ]
  },
  {
    "id": "3-studiengang",
    "parent": "0-atrium",
    "name": "Studiengänge",
    hasChildren: true,
    hasMenu: true,
    "inSubmenu": true,
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 2.283977011239683,
      "pitch": 0.06580456064019558,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": 1.3623835776475488,
        "pitch": 0.023246339338040656,
        "rotation": 0,
        "target": "5-poster-flexbiomethane",
        isRoom: true
      },
      {
        "yaw": 1.1994535062510092,
        "pitch": 0.03394483538415649,
        "rotation": 0,
        "target": "5-waermenetzprojekte",
        isRoom: true
      },
      {
        "yaw": -1.2505413820258084,
        "pitch": 0.10879728551655354,
        "rotation": 0,
        "target": "1-empfang--anmeldung"
      },
     /* {
        "yaw": -0.7457848855391749,
        "pitch": -0.04393081989590186,
        "rotation": 0,
        "target": "7-biogas-anlage"
      }, */
      {
        "yaw": -1.9925611515391282,
        "pitch": 0.20615660272567027,
        "rotation": 0,
        "target": "2--institut-fr-neue-energie-systeme-ines"
      },
    /*  {
        "yaw": -2.9377470608964806,
        "pitch": -0.020020781646586272,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -2.9377470608964806,
        "pitch": -0.100020781646586272,
        "rotation": 0,
        "target": "0-lobby"
      }, */
      {
        "yaw": 1.5416930077265043,
        "pitch": 0.0484435752221799,
        "rotation": 0,
        "target": "4-poster-tav-control",
        isRoom: true
      },
      {
        "yaw": 1.0335129736230897,
        "pitch": 0.03347455002862176,
        "rotation": 0,
        "target": "8-grammersolar",
        isRoom: true
      },
      {
        "yaw": 0.8588480415811617,
        "pitch": 0.027451489484736413,
        "rotation": 0,
        "target": "8-omnicert",
        isRoom: true
      },
      {
        "yaw": 0.6754463519013463,
        "pitch": 0.028623944909456966,
        "rotation": 0,
        "target": "9-carmen",
        isRoom: true
      },
      {
        "yaw": 0.48695359486499257,
        "pitch": 0.02239394435616049,
        "rotation": 0,
        "target": "9-lew-lechwerke",
        isRoom: true
      },
      {
        "yaw": 0.2881301071322806,
        "pitch": 0.014386459997490775,
        "rotation": 0,
        "target": "10-schwaben-regenerativ",
        isRoom: true
      },
      {
        "yaw": 0.07474448482514795,
        "pitch": 0.017817909327963122,
        "rotation": 0,
        "target": "11-ratiotherm",
        isRoom: true
      },
      {
        "yaw": -0.11996190517210614,
        "pitch": 0.02297502623617831,
        "rotation": 0,
        "target": "11-enerpipe",
        isRoom: true
      },
      {
        "yaw": -0.35196195539213804,
        "pitch": 0.020457133316192255,
        "rotation": 0,
        "target": "12-varmeco",
        isRoom: true
      }
    ],
    "infoHotspots": [
      {
        yaw: 2.33,
        pitch: 0,
        translateX: "-100px",
        translateY: "-500px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>Studiengänge im Bereich Erneuerbare Energien</h2>",
      },{
        yaw: 2.33,
        pitch: 0,
        translateX: "-100px",
        translateY: "-350px",
        url: '#',
        params: {
          url: '#',
        },

        title: "<b>Ansprechpartner für Bachelor-Studiengang: Prof. Dr.-Ing. Tobias Schrag<br />Ansprechpartner für Master-Studiengang:  Prof. Dr.-Ing. Sabine Bschorer</b><br /><small>Wir freuen uns über Ihre Nachricht im Veranstaltungs-Chat!</small>",
        showInNavbar: true,
      },  {
        yaw: 2.33,
        pitch: 0,          
        type: "quer",
        translateX: "-530px",
        translateY: "-200px",
        action: "showImageBox",
        thumb: "/material/210616_studiengang/video/thumb.jpg",
        params: {
            video: "/material/210616_studiengang/video/video.mp4",
            type: "quer",
        },
        title: "Video zum Studiengang",
        showInNavbar: true,
      },
     {
      yaw: 2.33,
      pitch: 0, 
      translateX: "-450px",
      translateY: "550px",
      action: "openExternalLink",
      url: "https://www.thi.de/maschinenbau/studiengaenge/energiesysteme-und-erneuerbare-energien-beng",
      params: {
        url: "https://www.thi.de/maschinenbau/studiengaenge/energiesysteme-und-erneuerbare-energien-beng",
      },
      title: "Studiengang Energiesysteme und Erneuerbare Energien (B. Eng.)",
      showInNavbar: true,
    },
      {
        yaw: 2.33, 
        pitch: 0, 
        translateX: "-550px",
        translateY: "670px",
        action: "openExternalLink",
        url: "https://www.thi.de/maschinenbau/studiengaenge/energy-systems-and-renewable-energies-beng",
        params: {
          url: "https://www.thi.de/maschinenbau/studiengaenge/energy-systems-and-renewable-energies-beng",
        },
        title: "Studiengang Energy Systems and Renewable Energies (B. Eng., auf Englisch)",
        showInNavbar: true,
      },
      {
        yaw: 2.35,
        pitch: 0,
        translateX: "-480px",
        translateY: "150px",
        action: "showImageBox",
        thumb: "/material/210616_studiengang/flyer_bachelor/x_Seite_1.jpg",
        type: "halbquer",
        params: {
          slides: [
            "/material/210616_studiengang/flyer_bachelor/x_Seite_1.jpg",
            "/material/210616_studiengang/flyer_bachelor/x_Seite_2.jpg",
            "/material/210616_studiengang/flyer_bachelor/x_Seite_3.jpg",
            "/material/210616_studiengang/flyer_bachelor/x_Seite_4.jpg",
            "/material/210616_studiengang/flyer_bachelor/x_Seite_5.jpg",
            "/material/210616_studiengang/flyer_bachelor/x_Seite_6.jpg",
          ],
          pdf: "/material/210616_studiengang/flyer_bachelor/Studiengangflyer_2021.pdf",
        },
        title: "Bachelorstudiengang Energiesysteme und Erneuerbare Energien",
        showInNavbar: true,
       },
       {
        yaw: 2.35,
        pitch: 0,
        translateX: "-100px",
        translateY: "150px",
        action: "showImageBox",
        thumb: "/material/210616_studiengang/flyer_master/x_Seite_1.jpg",
        type: "halbquer",
        params: {
          type: "quer",
          slides: [
            "/material/210616_studiengang/flyer_master/x_Seite_1.jpg",
            "/material/210616_studiengang/flyer_master/x_Seite_2.jpg"
          ],
          pdf: "/material/210616_studiengang/flyer_master/2017_Flyer_Renewable_Energy_Systems_final.pdf",
        },
        title: "Masterstudiengang Renewable Energy Systems",
        showInNavbar: true,
       },{
        yaw: 2.35,
        pitch: 0,
        translateX: "20px",
        translateY: "-190px",
        action: "showImageBox",
        type: "quadrat",
        thumb: "/material/210616_studiengang/flyer_bachelor/x_Seite_6.jpg",
        params: {
          slides: [
            "/material/210616_studiengang/flyer_bachelor/x_Seite_6.jpg",
          ],
          pdf: "/material/210616_studiengang/flyer_bachelor/x_Seite_6.jpg",
        },
        title: "Die THI in Zahlen",
        showInNavbar: true,
       },
       
    ]
  },
  {
    "id": "4-poster-tav-control",
    "parent": "0-atrium",
    "name": "EFRE Bayern",
    hasChildren: true,
    hasMenu: true,
    "inSubmenu": true,
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 2.1913099867135726,
      "pitch": 0.0694521292313388,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": 2.8995291081616443,
        "pitch": 0.045177785887387856,
        "rotation": 0,
        "target": "3-studiengang",
        isRoom: true
      },
      {
        "yaw": -2.8414294983069333,
        "pitch": 0.14746016147653052,
        "rotation": 0,
        "target": "2--institut-fr-neue-energie-systeme-ines"
      },
      {
        "yaw": -2.264818771873502,
        "pitch": 0.10258791495312991,
        "rotation": 0,
        "target": "1-empfang--anmeldung"
      },
   /*   {
        "yaw": -1.861452426615216,
        "pitch": 0.007795300277942729,
        "rotation": 0,
        "target": "7-biogas-anlage"
      },
      {
        "yaw": 1.430358067178049,
        "pitch": -0.059345181152906434,
        "rotation": 0,
        "target": "6-wrmetechnischer-prfstand"
      },*/
      {
        "yaw": 0.845110363001286,
        "pitch": 0.062129871929762714,
        "rotation": 0,
        "target": "5-poster-flexbiomethane",
        isRoom: true
      },
      {
        "yaw": 0.5028905519695641,
        "pitch": 0.060639535301183045,
        "rotation": 0,
        "target": "5-waermenetzprojekte",
        isRoom: true
      },
      {
        "yaw": 0.24526097599924057,
        "pitch": 0.04467576400730522,
        "rotation": 0,
        "target": "8-grammersolar",
        isRoom: true
      },
      {
        "yaw": -0.0005823717020394525,
        "pitch": 0.03467346178835484,
        "rotation": 0,
        "target": "8-omnicert",
        isRoom: true
      },
      {
        "yaw": -0.257665703191968,
        "pitch": 0.03314668683989197,
        "rotation": 0,
        "target": "9-carmen",
        isRoom: true
      },
      {
        "yaw": -0.5226052693840426,
        "pitch": 0.01452901179396271,
        "rotation": 0,
        "target": "9-lew-lechwerke",
        isRoom: true
      },
      {
        "yaw": -0.7795525487719566,
        "pitch": 0.01617437790194387,
        "rotation": 0,
        "target": "10-schwaben-regenerativ",
        isRoom: true
      },
      {
        "yaw": -1.0123828270379391,
        "pitch": 0.014014716021289786,
        "rotation": 0,
        "target": "11-ratiotherm",
        isRoom: true
      },
      {
        "yaw": -1.225540350075926,
        "pitch": 0.009074540107953055,
        "rotation": 0,
        "target": "11-enerpipe",
        isRoom: true
      },
      {
        "yaw": -1.4676972345381234,
        "pitch": 0.014436027581904654,
        "rotation": 0,
        "target": "12-varmeco",
        isRoom: true
      }
    ],
    "infoHotspots": [
      {
        yaw: 2.15,
        pitch: 0,
        translateX: "20px",
        translateY: "-540px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>EFRE in Bayern</h2>",
      },{
        yaw: 2.15,
        pitch: 0,
        translateX: "20px",
        translateY: "-410px",
        url: '#',
        params: {
          url: '#',
        },
        title: "<b>Kontakt:</b><br /><small>infoefre@stmwi.bayern.de</small>",
        showInNavbar: true,
      },{
      yaw: 2.15,
      pitch: 0, 
      translateX: "-570px",
      translateY: "550px",
      radius: 1100,
      action: "openExternalLink",
      url: "https://www.efre-bayern.de",
      params: {
        url: "https://www.efre-bayern.de",
      },
      title: "Weitere Projekte, ein EFRE-Quiz, Informationen zu REACT-EU, zur neuen Förderperiode <br />und vieles mehr finden Sie auf unserer Website <b>efre-bayern.de</b>!</small>",
      showInNavbar: true,
    },   
    {
      yaw: 2.15,
      pitch: 0,
      translateX: "-110px",
      translateY: "-240px",
      action: "showImageBox",
      thumb: "/material/210616_efre/buergerinfo/thumb.jpg",
      type: "quadrat",
      params: {
        type: "quer",
        slides: [
          "/material/210616_efre/buergerinfo/x_Seite_01.jpg",
          "/material/210616_efre/buergerinfo/x_Seite_02.jpg",
          "/material/210616_efre/buergerinfo/x_Seite_03.jpg",
          "/material/210616_efre/buergerinfo/x_Seite_04.jpg",
          "/material/210616_efre/buergerinfo/x_Seite_05.jpg",
          "/material/210616_efre/buergerinfo/x_Seite_06.jpg",
          "/material/210616_efre/buergerinfo/x_Seite_07.jpg",
          "/material/210616_efre/buergerinfo/x_Seite_08.jpg",
          "/material/210616_efre/buergerinfo/x_Seite_09.jpg",
          "/material/210616_efre/buergerinfo/x_Seite_10.jpg",
          "/material/210616_efre/buergerinfo/x_Seite_11.jpg",
          "/material/210616_efre/buergerinfo/x_Seite_12.jpg",
          "/material/210616_efre/buergerinfo/x_Seite_13.jpg",
          "/material/210616_efre/buergerinfo/x_Seite_14.jpg"
        ],
        pdf: "/material/210616_efre/buergerinfo/2022-06-23_EFRE_Buergerinfo_2022_nach_BGA_BF",
      },
      title: "Bürgerinfo 2022/23",
      showInNavbar: true,
     },{
      yaw: 2.15,
      pitch: 0,
      translateX: "-410px",
      translateY: "-150px",
      action: "showImageBox",
      thumb: "/material/210616_efre/plakat/thumb.jpg",
      type: "hoch",
      params: {
        slides: [
          "/material/210616_efre/plakat/x_Seite_1.jpg",
        ],
        pdf: "/material/210616_efre/plakat/Plakat.pdf",
      },
      title: "EFRE-Plakat 2021/22",
      showInNavbar: true,
     },{
      yaw: 2.15,
      pitch: 0,          
      type: "quer",
      translateX: "-110px",
      translateY: "100px",
      action: "showImageBox",
      thumb: "/material/210616_efre/video/thumb.jpg",
      params: {
          video: "/material/210616_efre/video/video.mp4",
          type: "quer",
      },
      title: "EFRE-Film",
      showInNavbar: true,
    },{
      yaw: 2.15,
      pitch: 0,          
      type: "lang",
      translateX: "-5px",
      translateY: "720px",
      thumb: "/material/210616_efre/foerderhinweis.png",
      url: '#',
      params: {
        url: '#',
      },
      showInNavbar: true,
    },
    ], 
  },
  {
    "id": "5-waermenetzprojekte",
    "parent": "0-atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "Wärmenetz-Projekte",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 1.7,
      "pitch": 0.06021389816900012,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": -2.608138302844063,
        "pitch": 0.05123737961849173,
        "rotation": 0,
        "target": "4-poster-tav-control",
        isRoom: true
      },
      {
        "yaw": -2.2723391256762824,
        "pitch": 0.09154337328335593,
        "rotation": 0,
        "target": "2--institut-fr-neue-energie-systeme-ines"
      },
      {
        "yaw": -1.8606334369339077,
        "pitch": 0.060433930387489454,
        "rotation": 0,
        "target": "1-empfang--anmeldung"
      },
     /* {
        "yaw": -1.5716286965528035,
        "pitch": 0.003380692099831606,
        "rotation": 0,
        "target": "7-biogas-anlage"
      },
      {
        "yaw": -2.9016286965528035,
        "pitch": -0.053380692099831606,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -2.9016286965528035,
        "pitch": -0.123380692099831606,
        "rotation": 0,
        "target": "0-lobby"
      },*/
      {
        "yaw": 2.4340627428081394,
        "pitch": 0.06027631093131447,
        "rotation": 0,
        "target": "5-poster-flexbiomethane",
        isRoom: true
      },
      {
        "yaw": 0.5771915738960907,
        "pitch": 0.06295157195180323,
        "rotation": 0,
        "target": "8-grammersolar",
        isRoom: true
      },
      {
        "yaw": 0.25145672206994973,
        "pitch": 0.03843642350886789,
        "rotation": 0,
        "target": "8-omnicert",
        isRoom: true
      },
      {
        "yaw": -0.09225118491127304,
        "pitch": 0.04657274047684545,
        "rotation": 0,
        "target": "9-carmen",
        isRoom: true
      },
      {
        "yaw": -0.4308786779798126,
        "pitch": 0.03167082527404119,
        "rotation": 0,
        "target": "9-lew-lechwerke",
        isRoom: true
      },
      {
        "yaw": -0.6716577744772199,
        "pitch": 0.0266391883905861,
        "rotation": 0,
        "target": "10-schwaben-regenerativ",
        isRoom: true
      },
      {
        "yaw": -0.8632144567645383,
        "pitch": 0.01670710317109858,
        "rotation": 0,
        "target": "11-ratiotherm",
        isRoom: true
      },
      {
        "yaw": -1.0301489895974854,
        "pitch": 0.013075647333320006,
        "rotation": 0,
        "target": "11-enerpipe",
        isRoom: true
      },
      {
        "yaw": -1.2409259361734897,
        "pitch": 0.0077327077383095855,
        "rotation": 0,
        "target": "12-varmeco",
        isRoom: true
      }
    ],
    "infoHotspots": [  
      {
        yaw: 1.6,
        pitch: 0,
        translateX: "-520px",
        translateY: "-300px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>Wärmenetz-Projekte</h2>",
      },{
        yaw: 1.6,
        pitch: 0,
        translateX: "-520px",
        translateY: "-150px",
        url: '#',
        params: {
          url: '#',
        },
        title: "<b>Mathias Ehrenwirth</b><br /><small>Ich freue mich über Ihre E-Mail an mathias.ehrenwirth@thi.de!</small>",
        showInNavbar: true,
      },,{
      yaw: 1.6,
      pitch: 0,
      translateX: "-800px",
      translateY: "0px",
      action: "showImageBox",
      thumb: "/material/210616_poster-smartsolgrid/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/210616_poster-smartsolgrid/x_Seite_1.jpg",
        ],
        pdf: "/material/210616_poster-smartsolgrid/poster.png",
      },
      title: "Poster smartsolgrid",
      showInNavbar: true,
     },
     {
      yaw: 1.6,
      pitch: 0,
      translateX: "-450px",
      translateY: "0px",
      action: "showImageBox",
      thumb: "/material/210616_poster-natar/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/210616_poster-natar/x_Seite_1.jpg",
        ],
        pdf: "/material/210616_poster-natar/Gesamtuebersicht.pdf",
      },
      title: "Poster NATAR",
      showInNavbar: true,
     },{
      yaw: 1.6,
      pitch: 0, 
      translateX: "-940px",
      translateY: "500px",
      action: "openExternalLink",
      url: "https://www.thi.de/fileadmin/daten/forschung/InES/Projektsteckbriefe/Gebaeudeenergiesysteme/SmartSOLgrid.pdf",
      params: {
        url: "https://www.thi.de/fileadmin/daten/forschung/InES/Projektsteckbriefe/Gebaeudeenergiesysteme/SmartSOLgrid.pdf",
      },
      title: "Projektsteckbrief smartsolgrid",
      showInNavbar: true,
    },
     {
      yaw: 1.6,
      pitch: 0, 
      translateX: "-480px",
      translateY: "500px",
      action: "openExternalLink",
      url: "https://www.thi.de/fileadmin/daten/forschung/InES/Projektsteckbriefe/Projektsteckbrief_NATAR.pdf",
      params: {
        url: "https://www.thi.de/fileadmin/daten/forschung/InES/Projektsteckbriefe/Projektsteckbrief_NATAR.pdf",
      },
      title: "Projektsteckbrief NATAR",
      showInNavbar: true,
  }
    ]
  },
  {
    "id": "5-poster-flexbiomethane",
    "parent": "0-atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "Bioenergie-Projekte",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 2.145631548461295,
      "pitch": 0.1,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": -2.608138302844063,
        "pitch": 0.05123737961849173,
        "rotation": 0,
        "target": "4-poster-tav-control",
        isRoom: true
      },
      {
        "yaw": -2.2723391256762824,
        "pitch": 0.09154337328335593,
        "rotation": 0,
        "target": "2--institut-fr-neue-energie-systeme-ines"
      },
      {
        "yaw": -1.8606334369339077,
        "pitch": 0.060433930387489454,
        "rotation": 0,
        "target": "1-empfang--anmeldung"
      },
     /* {
        "yaw": -1.5716286965528035,
        "pitch": 0.003380692099831606,
        "rotation": 0,
        "target": "7-biogas-anlage"
      },
      {
        "yaw": -2.9016286965528035,
        "pitch": -0.053380692099831606,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -2.9016286965528035,
        "pitch": -0.123380692099831606,
        "rotation": 0,
        "target": "0-lobby"
      },*/
      {
        "yaw": 1.1971915738960907,
        "pitch": 0.06295157195180323,
        "rotation": 0,
        "target": "5-waermenetzprojekte",
        isRoom: true
      },
      {
        "yaw": 0.5771915738960907,
        "pitch": 0.06295157195180323,
        "rotation": 0,
        "target": "8-grammersolar",
        isRoom: true
      },
      {
        "yaw": 0.25145672206994973,
        "pitch": 0.03843642350886789,
        "rotation": 0,
        "target": "8-omnicert",
        isRoom: true
      },
      {
        "yaw": -0.09225118491127304,
        "pitch": 0.04657274047684545,
        "rotation": 0,
        "target": "9-carmen",
        isRoom: true
      },
      {
        "yaw": -0.4308786779798126,
        "pitch": 0.03167082527404119,
        "rotation": 0,
        "target": "9-lew-lechwerke",
        isRoom: true
      },
      {
        "yaw": -0.6716577744772199,
        "pitch": 0.0266391883905861,
        "rotation": 0,
        "target": "10-schwaben-regenerativ",
        isRoom: true
      },
      {
        "yaw": -0.8632144567645383,
        "pitch": 0.01670710317109858,
        "rotation": 0,
        "target": "11-ratiotherm",
        isRoom: true
      },
      {
        "yaw": -1.0301489895974854,
        "pitch": 0.013075647333320006,
        "rotation": 0,
        "target": "11-enerpipe",
        isRoom: true
      },
      {
        "yaw": -1.2409259361734897,
        "pitch": 0.0077327077383095855,
        "rotation": 0,
        "target": "12-varmeco",
        isRoom: true
      }
    ],
    "infoHotspots": [
      {
        yaw: 2.2,
        pitch: 0,
        translateX: "20px",
        translateY: "-390px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>Bioenergie-Projekte</h2>",
      },{
        yaw: 2.2,
        pitch: 0,
        translateX: "20px",
        translateY: "-240px",
        url: '#',
        params: {
          url: '#',
        },
        title: "<b>Prof. Dr.-Ing. Markus Goldbrunner</b><br /><small>Ich freue mich über Ihre E-Mail an markus.goldbrunner@thi.de!</small>",
        showInNavbar: true,
      },{
        yaw: 2.2,
        pitch: 0, 
        translateX: "-480px",
        translateY: "550px",
        action: "openExternalLink",
        url: "https://www.thi.de/fileadmin/daten/forschung/InES/Projektsteckbriefe/Industrielle_Energiesysteme/2021-03_FlexBiomethane_de.pdf",
        params: {
          url: "https://www.thi.de/fileadmin/daten/forschung/InES/Projektsteckbriefe/Industrielle_Energiesysteme/2021-03_FlexBiomethane_de.pdf",
        },
        title: "Projektsteckbrief FlexBiomethane",
        showInNavbar: true,
      },{
        yaw: 2.2,
        pitch: 0, 
        translateX: "90px",
        translateY: "550px",
        action: "openExternalLink",
        url: "https://www.th-in.de/fileadmin/daten/forschung/InES/Projektsteckbriefe/Industrielle_Energiesysteme/2021-03_KomBio_de.pdf",
        params: {
          url: "https://www.th-in.de/fileadmin/daten/forschung/InES/Projektsteckbriefe/Industrielle_Energiesysteme/2021-03_KomBio_de.pdf",
        },
        title: "Projektsteckbrief Kombio",
        showInNavbar: true,
      },{
      yaw: 2.2,
      pitch: 0,
      translateX: "-320px",
      translateY: "0px",
      action: "showImageBox",
      thumb: "/material/210616_poster-flexbiomethane/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/210616_poster-flexbiomethane/x_Seite_1.jpg",
        ],
        pdf: "/material/210616_poster-flexbiomethane/Poster_FlexBiomethane.pdf",
      },
      title: "Poster FlexBiomethane",
      showInNavbar: true,
     },{
      yaw: 2.2,
      pitch: 0,
      translateX: "20px",
      translateY: "0px",
      action: "showImageBox",
      thumb: "/material/ines/kombio/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/ines/kombio/x_Seite_1.jpg"
        ],
        pdf: "/material/ines/kombio/Poster_Kombio.pdf",
      },
      title: "Poster Kombio",
      showInNavbar: true,
     }

    ]
  },
  {
    "id": "8-grammersolar",
    "parent": "0-atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "Grammer Solar",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 1.03,
      "pitch": 0.05569761155570262,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": 1.8882530266607175,
        "pitch": 0.07918034757379289,
        "rotation": 0,
        "target": "5-waermenetzprojekte",
        isRoom: true
      }, 
      {
        "yaw": 0.20882530266607175,
        "pitch": 0.07918034757379289,
        "rotation": 0,
        "target": "8-omnicert",
        isRoom: true
      },
      {
        "yaw": 2.6840627428081394,
        "pitch": 0.06027631093131447,
        "rotation": 0,
        "target": "5-poster-flexbiomethane",
        isRoom: true
      },
      {
        "yaw": 3.041439029137729,
        "pitch": 0.06417107932332655,
        "rotation": 0,
        "target": "4-poster-tav-control",
        isRoom: true
      },
      {
        "yaw": -2.9019962089606146,
        "pitch": 0.10183691370796666,
        "rotation": 0,
        "target": "2--institut-fr-neue-energie-systeme-ines"
      },
      {
        "yaw": -2.537236254258449,
        "pitch": 0.08364704195689754,
        "rotation": 0,
        "target": "1-empfang--anmeldung"
      },
      {
        "yaw": -1.4654446981396276,
        "pitch": 0.03326450113911861,
        "rotation": 0,
        "target": "12-varmeco",
        isRoom: true
      },
      {
        "yaw": -1.3090109177490605,
        "pitch": -0.05549184323175325,
        "rotation": 0,
        "target": "11-enerpipe",
        isRoom: true
      },
      {
        "yaw": -1.2302748976450356,
        "pitch": 0.030242846550063263,
        "rotation": 0,
        "target": "11-ratiotherm",
        isRoom: true
      },
      {
        "yaw": -1.0830227384185939,
        "pitch": -0.02884131045679794,
        "rotation": 0,
        "target": "10-schwaben-regenerativ",
        isRoom: true
      },
      {
        "yaw": -0.9220724481969256,
        "pitch": 0.03914575034594492,
        "rotation": 0,
        "target": "9-lew-lechwerke",
        isRoom: true
      },
      {
        "yaw": -0.7283707262462382,
        "pitch": 0.05700226491331151,
        "rotation": 0,
        "target": "9-carmen",
        isRoom: true
      },
      /*{
        "yaw": -2.108391240609043,
        "pitch": -0.023417777718400146,
        "rotation": 0,
        "target": "7-biogas-anlage"
      },
      {
        "yaw": -3.408391240609043,
        "pitch": -0.023417777718400146,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -3.408391240609043,
        "pitch": -0.093417777718400146,
        "rotation": 0,
        "target": "0-lobby"
      }*/
    ],
    "infoHotspots": [
      {
        yaw: 1.08,
        pitch: 0,
        translateX: "-20px",
        translateY: "-400px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>Grammer Solar</h2>",
      },{
        yaw: 1.08,
        pitch: 0,
        translateX: "-20px",
        translateY: "-270px",
        url: '#',
        params: {
          url: '#',
        },
        title: "<b>Rudolf Ettl</b><br /><small>Ich freue mich über Ihre E-Mail an r.ettl@grammer-solar.de!</small>",
        showInNavbar: true,
      },{
      yaw: 1.10,
      pitch: 0, 
      translateX: "-290px",
      translateY: "540px",
      action: "openExternalLink",
      url: "https://www.grammer-solar.com/de/solarluft/solarlueften.html",
      params: {
        url: "hhttps://www.grammer-solar.com/de/solarluft/solarlueften.html",
      },
      title: "Mehr Infos auf unserer Website",
      showInNavbar: true,
  },{
    yaw: 1.04,
    pitch: 0,
    translateX: "-330px",
    translateY: "-150px",
    action: "showImageBox",
    
    thumb: "/material/210616_grammersolar/solareprozesswaerme/x_Seite_1.jpg",
    type: "halbquer",
    params: {
      type: "quer",
      slides: [
        "/material/210616_grammersolar/solareprozesswaerme/x_Seite_1.jpg",
        "/material/210616_grammersolar/solareprozesswaerme/x_Seite_2.jpg",
      ],
      pdf: "/material/210616_grammersolar/solareprozesswaerme/Prospekt_SolareProzesswaerme_0150102009.pdf",
    },
    title: "Solare Prozesswärme",
    showInNavbar: true,
   },{
    yaw: 1.04,
    pitch: 0,
    translateX: "40px",
    translateY: "-150px",
    action: "showImageBox",
    thumb: "/material/210616_grammersolar/twinsolar/x_Seite_1.jpg",
    type: "halbquer",
    params: {
      type: "quer",
      slides: [
        "/material/210616_grammersolar/twinsolar/x_Seite_1.jpg",
        "/material/210616_grammersolar/twinsolar/x_Seite_2.jpg",
      ],
      pdf: "/material/210616_grammersolar/twinsolar/Prospekt_TwinSolar_compact_0150000050-DE.pdf",
    },
    title: "TwinSolar",
    showInNavbar: true,
   },{
    yaw: 1.04,
    pitch: 0,
    translateX: "-300px",
    translateY: "170px",
    action: "showImageBox",
    thumb: "/material/210616_grammersolar/solaretrocknung/x_Seite_1.jpg",
    type: "small",
    params: {
      slides: [
        "/material/210616_grammersolar/solaretrocknung/x_Seite_1.jpg",
        "/material/210616_grammersolar/solaretrocknung/x_Seite_2.jpg",
        "/material/210616_grammersolar/solaretrocknung/x_Seite_3.jpg",
        "/material/210616_grammersolar/solaretrocknung/x_Seite_4.jpg",
      ],
      pdf: "/material/210616_grammersolar/solaretrocknung/Faltblatt_Solare-Trocknung_0150000054-DE.pdf",
    },
    title: "Referenzen",
    showInNavbar: true,
   },{
    yaw: 1.04,
    pitch: 0,
    translateX: "160px",
    translateY: "170px",
    action: "showImageBox",
    thumb: "/material/210616_grammersolar/solarlueften/x_Seite_01.jpg",
    type: "small",
    params: {
      slides: [
        "/material/210616_grammersolar/solarlueften/x_Seite_01.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_03.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_04.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_05.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_07.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_08.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_09.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_10.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_11.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_12.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_13.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_14.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_15.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_16.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_17.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_18.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_19.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_20.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_21.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_22.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_23.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_24.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_25.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_26.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_27.jpg",
        "/material/210616_grammersolar/solarlueften/x_Seite_28.jpg",
      ],
      pdf: "/material/210616_grammersolar/solarlueften/Broschuere_SolarLueften_0150100060-DE.pdf",
    },
    title: "SolarLüften",
    showInNavbar: true,
   },{
    yaw: 1.04,
    pitch: 0,
    translateX: "-72px",
    translateY: "170px",
    action: "showImageBox",
    thumb: "/material/210616_grammersolar/poster/x_Seite_1.jpg",
    type: "small",
    params: {
      slides: [
        "/material/210616_grammersolar/poster/x_Seite_1.jpg",
      ],
      pdf: "/material/210616_grammersolar/poster/Poster_A1_TwinCompact_1701.pdf",
    },
    title: "Poster",
    showInNavbar: true,
   }
    ]
  },  {
    "id": "8-omnicert",
    "parent": "0-atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "OmniCert",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 1.11222977981330452,
      "pitch": 0.04569761155570262,
      "fov": 1.4
    },
    "linkHotspots": [
      {
        "yaw": 2.6882530266607175,
        "pitch": 0.07918034757379289,
        "rotation": 0,
        "target": "5-waermenetzprojekte",
        isRoom: true
      },
      {
        "yaw": 2.0882530266607175,
        "pitch": 0.07918034757379289,
        "rotation": 0,
        "target": "8-grammersolar",
        isRoom: true
      },
      {
        "yaw": 3.041439029137729,
        "pitch": 0.06417107932332655,
        "rotation": 0,
        "target": "5-poster-flexbiomethane",
        isRoom: true
      },
      {
        "yaw": 3.651439029137729,
        "pitch": 0.06417107932332655,
        "rotation": 0,
        "target": "4-poster-tav-control",
        isRoom: true
      },
      {
        "yaw": -2.35,
        "pitch": 0.05183691370796666,
        "rotation": 0,
        "target": "2--institut-fr-neue-energie-systeme-ines"
      },
      {
        "yaw": -1.96,
        "pitch": 0.08364704195689754,
        "rotation": 0,
        "target": "1-empfang--anmeldung"
      },
      {
        "yaw": -1.44,
        "pitch": 0.03326450113911861,
        "rotation": 0,
        "target": "12-varmeco",
        isRoom: true
      },
      {
        "yaw": -1.25,
        "pitch": 0.03549184323175325,
        "rotation": 0,
        "target": "11-enerpipe",
        isRoom: true
      },
      {
        "yaw": -1.11,
        "pitch": 0.030242846550063263,
        "rotation": 0,
        "target": "11-ratiotherm",
        isRoom: true
      },
      {
        "yaw": -1.0,
        "pitch": 0.02884131045679794,
        "rotation": 0,
        "target": "10-schwaben-regenerativ",
        isRoom: true
      },
      {
        "yaw": -0.7283707262462382,
        "pitch": 0.03914575034594492,
        "rotation": 0,
        "target": "9-lew-lechwerke",
        isRoom: true
      },
      {
        "yaw": -0.1,
        "pitch": 0.05700226491331151,
        "rotation": 0,
        "target": "9-carmen",
        isRoom: true
      },
     /* {
        "yaw": -1.708391240609043,
        "pitch": -0.023417777718400146,
        "rotation": 0,
        "target": "7-biogas-anlage"
      },
      {
        "yaw": -2.808391240609043,
        "pitch": -0.023417777718400146,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -2.808391240609043,
        "pitch": 0.043417777718400146,
        "rotation": 0,
        "target": "0-lobby"
      },
      {
        "yaw": -3.808391240609043,
        "pitch": -0.023417777718400146,
        "rotation": 0,
        "target": "6-wrmetechnischer-prfstand"
      }*/
    ],
    "infoHotspots": [{
      yaw: 1.14,
      pitch: 0,
      translateX: "-100px",
      translateY: "-400px",
      url: '#',
      isTitle: true,
      params: {
        url: '#',
      },
      title: "<h2>OmniCert</h2>",
    },{
      yaw: 1.14,
      pitch: 0,
      translateX: "-100px",
      translateY: "-250px",
      url: '#',
      params: {
        url: '#',
      },
      title: "<b>Matthias Wutzlhofer</b><br /><small>Ich freue mich über Ihre E-Mail<br />an matthias.wutzlhofer@omnicert.de!</small>",
      showInNavbar: true,
    },{
      yaw: 1.14,
      pitch: 0, 
      translateX: "-300px",
      translateY: "520px",
      action: "openExternalLink",
      url: "https://www.umweltgutachter.de",
      params: {
        url: "https://www.umweltgutachter.de",
      },
      title: "www.umweltgutachter.de",
      showInNavbar: true,
    },  
         
      {
        yaw: 1.14,
        pitch: 0,
        translateX: "70px",
        translateY: "150px",
        type: "small",
        action: "showImageBox",
        thumb: "/material/210616_omnicert/sachverstaendige/x_Seite_1.jpg",
        params: { 
            slides: [
              "/material/210616_omnicert/sachverstaendige/x_Seite_1.jpg",
              "/material/210616_omnicert/sachverstaendige/x_Seite_2.jpg",
            ],
            pdf: "/material/210616_omnicert/sachverstaendige/Produktblatt_Sachverstaendige_OmniCert.pdf",
          },
          title: "Produktblatt Sachverständige",
          showInNavbar: true,
      },      
      {
        yaw: 1.14,
        pitch: 0,
        translateX: "70px",
        translateY: "-70px",
        action: "showImageBox",
        type: "halbquersmall",
        thumb: "/material/210616_omnicert/leistungsuebersicht/x_Seite_1.jpg",
        params: { 
          type: "quer",
          slides: [
              "/material/210616_omnicert/leistungsuebersicht/x_Seite_1.jpg",
          ],
          pdf: "/material/210616_omnicert/leistungsuebersicht/Leistungsuebersicht.pdf",
        },
        title: "Leistungsübersicht",
        showInNavbar: true,
      },
      {
        yaw: 1.14,
        pitch: 0,          
        type: "halbquersmall",
        translateX: "-520px",
        translateY: "0px",
        action: "showImageBox",
        thumb: "/material/210616_omnicert/video/thumb1.jpg",
        params: {
            video: "/material/210616_omnicert/video/video1.mp4",
            type: "quer",
        },
        title: "Video Bernhard Schwager",
        showInNavbar: true,
      },
      {
        yaw: 1.14,
        pitch: 0,       
        translateX: "-520px",
        translateY: "240px",
        type: "halbquersmall",
        action: "showImageBox",
        thumb: "/material/210616_omnicert/video/thumb2.jpg",
        params: {
            video: "/material/210616_omnicert/video/video2.mp4",
            type: "quer",
        },
        title: "Video Lisa Berg",
        showInNavbar: true,
      },
      {
        yaw: 1.14,
        pitch: 0,      
        translateX: "-226px",
        translateY: "0",
        type: "halbquersmall",
        action: "showImageBox",
        thumb: "/material/210616_omnicert/video/thumb3.jpg",
        params: {
            video: "/material/210616_omnicert/video/video3.mp4",
            type: "quer",
        },
        title: "Video Richard Schuster",
        showInNavbar: true,
      },
      {
        yaw: 1.14,
        pitch: 0,       
        translateX: "-226px",
        translateY: "240px",
        type: "halbquersmall",
        action: "showImageBox",
        thumb: "/material/210616_omnicert/video/thumb4.jpg",
        params: {
            video: "/material/210616_omnicert/video/video4.mp4",
            type: "quer",
        },
        title: "Video Thomas Schindlbeck",
        showInNavbar: true,
      }
      
    ]

    
  },
  {
    "id": "9-carmen",
    "parent": "0-atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "LEW Lechwerke",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 1.3083232161371772,
      "pitch": 0.034516214766563635,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": 0.1494559715737065,
        "pitch": 0.05758146441292844,
        "rotation": 0,
        "target": "10-schwaben-regenerativ",
        isRoom: true
      },
      {
        "yaw": -0.04320661976069218,
        "pitch": 0.042656619025414955,
        "rotation": 0,
        "target": "11-ratiotherm",
        isRoom: true
      },
      {
        "yaw": -0.20237960285572143,
        "pitch": 0.039175186850325616,
        "rotation": 0,
        "target": "11-enerpipe",
        isRoom: true
      },
      {
        "yaw": -0.42142337989298007,
        "pitch": 0.023228635799526387,
        "rotation": 0,
        "target": "12-varmeco",
        isRoom: true
      },
      {
        "yaw": -1.0300151470007872,
        "pitch": 0.07096735739732551,
        "rotation": 0,
        "target": "1-empfang--anmeldung"
      },
      {
        "yaw": -1.3887700027248506,
        "pitch": 0.07982601597943173,
        "rotation": 0,
        "target": "2--institut-fr-neue-energie-systeme-ines"
      },
      {
        "yaw": -1.7116027304709789,
        "pitch": 0.01707664940308895,
        "rotation": 0,
        "target": "3-studiengang",
        isRoom: true
      },
      {
        "yaw": -1.8653746380674772,
        "pitch": 0.016875233455447614,
        "rotation": 0,
        "target": "4-poster-tav-control",
        isRoom: true
      },
      {
        "yaw": -2.382708722432671,
        "pitch": 0.027185681214554336,
        "rotation": 0,
        "target": "5-waermenetzprojekte",
        isRoom: true
      },
      {
        "yaw": -2.658417818049621,
        "pitch": 0.05103808427402079,
        "rotation": 0,
        "target": "5-poster-flexbiomethane",
        isRoom: true
      },
      {
        "yaw": -3.0186238721994183,
        "pitch": 0.02970499774522395,
        "rotation": 0,
        "target": "8-grammersolar",
        isRoom: true
      },
      {
        "yaw": 2.7482528054101376,
        "pitch": 0.04803789423183069,
        "rotation": 0,
        "target": "8-omnicert",
        isRoom: true
      },
     /* {
        "yaw": -0.575988226705892,
        "pitch": -0.061089058997310985,
        "rotation": 0,
        "target": "7-biogas-anlage"
      },
      {
        "yaw": -1.595988226705892,
        "pitch": -0.061089058997310985,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -1.595988226705892,
        "pitch": 0.02141089058997310985,
        "rotation": 0,
        "target": "0-lobby"
      }*/
    ],
    "infoHotspots": [
      {
        yaw: 1.25,
        pitch: 0,
        translateX: "-530px",
        translateY: "-580px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>C.A.R.M.E.N e.V.</h2>",
      },{
        yaw: 1.25,
        pitch: 0,
        translateX: "-530px",
        translateY: "-450px",
        url: '#',
        params: {
          url: '#',
        },
        title: "<b>Larissa Auzinger</b><br /><small>Ich freue mich über Ihe E-Mail an la@carmen-ev.de!</small>",

        showInNavbar: true,
      },  
     {
      yaw: 1.15,
      pitch: 0, 
      translateX: "-190px",
      translateY: "520px",
      action: "openExternalLink",
      url: "https://www.carmen-ev.de",
      params: {
        url: "https://www.carmen-ev.de",
      },
      title: "www.carmen-ev.de",
      showInNavbar: true,
    },
    {
      yaw: 1.35,
      pitch: 0,
      translateX: "900px",
      translateY: "-500px",
      url: '#',
      isTitle: true,
      params: {
        url: '#',
      },
      title: "<h2>LEW Lechwerke</h2>",
    },{
      yaw: 1.35,
      pitch: 0,
      translateX: "900px",
      translateY: "-350px",
      url: '#',
      params: {
        url: '#',
      },
      title: "<b>Andreas Ruhland</b><br /><small>Ich freue mich über Ihre E-Mail an andreas.ruhland@lew.de!</small>",
      showInNavbar: true,
    },{
    yaw: 1.35,
    pitch: 0, 
    translateX: "800px",
    translateY: "590px",
    action: "openExternalLink",
    url: "https://www.lew.de",
    params: {
      url: "https://www.lew.de",
    },
    title: "www.lew.de",
    showInNavbar: true,
},{
      yaw: 1.35,
      pitch: 0,
      translateX: "500px",
      translateY: "-120px",
      action: "showImageBox",
      thumb: "/material/210616_lew-lechwerke/eeg/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/210616_lew-lechwerke/eeg/x_Seite_1.jpg",
          "/material/210616_lew-lechwerke/eeg/x_Seite_2.jpg",
          "/material/210616_lew-lechwerke/eeg/x_Seite_3.jpg",
          "/material/210616_lew-lechwerke/eeg/x_Seite_4.jpg", 
        ],
        pdf: "/material/210616_lew-lechwerke/eeg/EEG_Marktpraemienmodell.pdf",
      },
      title: "EEG Marktpraemienmodell",
      showInNavbar: true,
     }, {
      yaw: 1.3,
      pitch: 0,
      translateX: "900px",
      translateY: "-80px",
      type: "quer",
      action: "showImageBox",
      thumb: "/material/210616_lew-lechwerke/LEnergiezukunft.jpg",
      params: {
        type: "quer",
        slides: [
          "/material/210616_lew-lechwerke/LEnergiezukunft.jpg"
        ]
      },
      title: "LEnergiezukunft",
      showInNavbar: true,
     }, {
      yaw: 1.35,
      pitch: 0,
      translateX: "520px",
      translateY: "420px",
      action: "openExternalLink",
      url: "https://www.lew.de/fuer-unternehmen/energievermarktung#/",
      params: {
        url: "https://www.lew.de/fuer-unternehmen/energievermarktung#/",
      },
      title: "Energievermarktung für Unternehmen & Anlagenbetreiber",
      showInNavbar: true,
     },  

     {
      yaw: 1.2,
      pitch: 0,
      translateX: "-960px",
      translateY: "-320px",
      action: "showImageBox",
      thumb: "/material/210616_carmen/holzvergasungsanlagen/x_Seite_01.jpg",
      type: "halbsmall",
      params: {
        slides: [
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_01.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_03.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_04.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_05.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_07.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_08.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_09.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_10.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_11.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_12.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_13.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_14.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_15.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_16.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_17.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_18.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_19.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_20.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_21.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_22.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_23.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_24.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_25.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_26.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_27.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_28.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_29.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_30.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_31.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_32.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_33.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_34.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_35.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_36.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_37.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_38.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_39.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_40.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_41.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_42.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_43.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_44.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_45.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_46.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_47.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_48.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_49.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_50.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_51.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_52.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_53.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_54.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_55.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_56.jpg"
        ],
        pdf: "/material/210616_carmen/holzvergasungsanlagen/Holzvergasungsanlagen.pdf",
      },
      title: "Holzvergasungsanlagen",
      showInNavbar: true,
     },      {
      yaw: 1.2,
      pitch: 0,
      translateX: "-700px",
      translateY: "-320px",
      action: "showImageBox",
      thumb: "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_01.jpg",
      type: "halbsmall",
      params: {
        slides: [
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_01.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_03.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_04.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_05.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_06.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_07.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_08.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_09.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_10.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_11.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_12.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_13.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_14.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_15.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_16.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_17.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_18.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_19.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_20.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_21.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_22.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_23.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_24.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_25.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_26.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_27.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_28.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_29.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_30.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_31.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_32.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_33.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_34.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_35.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_36.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_37.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_38.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_39.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_40.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_41.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_42.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_43.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_44.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_45.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_46.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_47.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_48.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_49.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_50.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_51.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_52.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_53.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_54.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_55.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_56.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_57.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_58.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_59.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_60.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_61.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_62.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_63.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_64.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_65.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_66.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_67.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_68.jpg",
        ],
        pdf: "/material/210616_carmen/kleinwindkraftanlagen/Kleinwindkraftanlagen.pdf",
      },
      title: "Kleinwindkraftanlagen",
      showInNavbar: true,
     }, {
      yaw: 1.2,
      pitch: 0,
      translateX: "-430px",
      translateY: "-320px",
      action: "showImageBox",
      type: "halbsmall",
      thumb: "/material/210616_carmen/photovoltaik/x_Seite_01.jpg",
      params: {
        slides: [
          "/material/210616_carmen/photovoltaik/x_Seite_01.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_02.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_03.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_04.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_05.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_06.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_07.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_08.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_09.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_10.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_11.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_12.jpg",
        ],
        pdf: "/material/210616_carmen/photovoltaik/Photovoltaikanlagen.pdf",
      },
      title: "Photovoltaikanlagen",
      showInNavbar: true,
     },{
      yaw: 1.2,
      pitch: 0,
      translateX: "-160px",
      translateY: "-320px",
      action: "showImageBox",
      type: "halbsmall",
      thumb: "/material/210616_carmen/waermeliefervertrag/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/210616_carmen/waermeliefervertrag/x_Seite_1.jpg",
          "/material/210616_carmen/waermeliefervertrag/x_Seite_2.jpg",
          "/material/210616_carmen/waermeliefervertrag/x_Seite_3.jpg",
          "/material/210616_carmen/waermeliefervertrag/x_Seite_4.jpg", 
        ],
        pdf: "/material/210616_carmen/waermeliefervertrag/Waermeliefervertrag.pdf",
      },
      title: "Wärmeliefervertrag",
      showInNavbar: true,
     },{
      yaw: 1.2,
      pitch: 0,
      translateX: "-960px",
      translateY: "80px",
      action: "showImageBox",
      type: "halbsmall",
      thumb: "/material/210616_carmen/energiewaelder/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/210616_carmen/energiewaelder/x_Seite_1.jpg",
          "/material/210616_carmen/energiewaelder/x_Seite_2.jpg",
          "/material/210616_carmen/energiewaelder/x_Seite_3.jpg",
          "/material/210616_carmen/energiewaelder/x_Seite_4.jpg", 
        ],
        pdf: "/material/210616_carmen/energiewaelder/energiewaelder.pdf",
      },
      title: "Energiewälder",
      showInNavbar: true,
     },{
      yaw: 1.2,
      pitch: 0,
      translateX: "-700px",
      translateY: "80px",
      action: "showImageBox",
      type: "halbsmall",
      thumb: "/material/210616_carmen/biomethan/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/210616_carmen/biomethan/x_Seite_1.jpg",
          "/material/210616_carmen/biomethan/x_Seite_2.jpg",
          "/material/210616_carmen/biomethan/x_Seite_3.jpg",
          "/material/210616_carmen/biomethan/x_Seite_4.jpg", 
          "/material/210616_carmen/biomethan/x_Seite_5.jpg",
          "/material/210616_carmen/biomethan/x_Seite_6.jpg",
          "/material/210616_carmen/biomethan/x_Seite_7.jpg",
          "/material/210616_carmen/biomethan/x_Seite_8.jpg", 
        ],
        pdf: "/material/210616_carmen/biomethan/Biomethan.pdf",
      },
      title: "Biomethan",
      showInNavbar: true,
     },{
      yaw: 1.2,
      pitch: 0,
      translateX: "-430px",
      translateY: "80px",
      action: "showImageBox",
      type: "halbsmall",
      thumb: "/material/210616_carmen/biogaswaerme/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/210616_carmen/biogaswaerme/x_Seite_1.jpg",
          "/material/210616_carmen/biogaswaerme/x_Seite_2.jpg",
          "/material/210616_carmen/biogaswaerme/x_Seite_3.jpg",
          "/material/210616_carmen/biogaswaerme/x_Seite_4.jpg", 
          "/material/210616_carmen/biogaswaerme/x_Seite_5.jpg",
          "/material/210616_carmen/biogaswaerme/x_Seite_6.jpg",
          "/material/210616_carmen/biogaswaerme/x_Seite_7.jpg",
          "/material/210616_carmen/biogaswaerme/x_Seite_8.jpg", 
        ],
        pdf: "/material/210616_carmen/biogaswaerme/biogaswaerme.pdf",
      },
      title: "Biogaswärme",
      showInNavbar: true,
     },
     {
      yaw: 1.2,
      pitch: 0,
      translateX: "-160px",
      translateY: "80px",        
      action: "showImageBox",
      type: "halbsmall",
      thumb: "/material/210616_carmen/soklappts/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/210616_carmen/soklappts/x_Seite_1.jpg",
          "/material/210616_carmen/soklappts/x_Seite_2.jpg",
          "/material/210616_carmen/soklappts/x_Seite_3.jpg",
          "/material/210616_carmen/soklappts/x_Seite_4.jpg",
        ],
        pdf: "/material/210616_carmen/soklappts/So-klappts-mit-dem-Stromliefervertrag.pdf",
      },
      title: "Stromliefervertrag",
      showInNavbar: true,
     }, {
      yaw: 1.2,
      pitch: 0,
      translateX: "-960px",
      translateY: "470px",
      action: "showImageBox",
      type: "scopesmall",
      thumb: "/material/210616_carmen/flyer/x_thumb.jpg",
      params: {
        type: "quer",
        slides: [
          "/material/210616_carmen/flyer/x1_Seite_1.jpg",
          "/material/210616_carmen/flyer/x1_Seite_2.jpg",
          "/material/210616_carmen/flyer/x2_Seite_1.jpg",
          "/material/210616_carmen/flyer/x2_Seite_2.jpg",
          "/material/210616_carmen/flyer/x3_Seite_1.jpg",
          "/material/210616_carmen/flyer/x3_Seite_2.jpg",
          "/material/210616_carmen/flyer/x4_Seite_1.jpg",
          "/material/210616_carmen/flyer/x4_Seite_2.jpg",
          "/material/210616_carmen/flyer/x5_Seite_1.jpg",
          "/material/210616_carmen/flyer/x5_Seite_2.jpg",
          "/material/210616_carmen/flyer/x6_Seite_1.jpg",
          "/material/210616_carmen/flyer/x6_Seite_2.jpg",
        ],
        pdf: "/material/210616_carmen/flyer/210616_carmen_Flyer.zip",
      },
      title: "Download Flyer-Package",
      showInNavbar: true,
     }
     /*{
      yaw: 1.25,
      pitch: 0,
      translateX: "-630px",
      translateY: "-630px",
      action: "showTextBox",
      thumb: "/assets/icons/live.png",
      type: "live", 
      params: {
        text: "<h2>LEW Lechwerke LIVE:</h2><b>eXergiemaschine - brachliegende Wärme nutzbar machen</b><br /><small>11.25 Uhr, Hauptbühne</small><br /><a href='https://meet.teamgeist.com/v/was-kommt-nach-der-oelheizung/live/mainstage'>Zur Hauptbühne</a>"
      }
    }, */
    ]
  },
  
  {
    "id": "9-lew-lechwerke",
    "parent": "0-atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "C.A.R.M.E.N",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 1.3083232161371772,
      "pitch": 0.034516214766563635,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": 0.1494559715737065,
        "pitch": 0.05758146441292844,
        "rotation": 0,
        "target": "10-schwaben-regenerativ",
        isRoom: true
      },
      {
        "yaw": -0.04320661976069218,
        "pitch": 0.042656619025414955,
        "rotation": 0,
        "target": "11-ratiotherm",
        isRoom: true
      },
      {
        "yaw": -0.20237960285572143,
        "pitch": 0.039175186850325616,
        "rotation": 0,
        "target": "11-enerpipe",
        isRoom: true
      },
      {
        "yaw": -0.42142337989298007,
        "pitch": 0.023228635799526387,
        "rotation": 0,
        "target": "12-varmeco",
        isRoom: true
      },
      {
        "yaw": -1.0300151470007872,
        "pitch": 0.07096735739732551,
        "rotation": 0,
        "target": "1-empfang--anmeldung"
      },
      {
        "yaw": -1.3887700027248506,
        "pitch": 0.07982601597943173,
        "rotation": 0,
        "target": "2--institut-fr-neue-energie-systeme-ines"
      },
      {
        "yaw": -1.7116027304709789,
        "pitch": 0.01707664940308895,
        "rotation": 0,
        "target": "3-studiengang",
        isRoom: true
      },
      {
        "yaw": -1.8653746380674772,
        "pitch": 0.016875233455447614,
        "rotation": 0,
        "target": "4-poster-tav-control",
        isRoom: true
      },
      {
        "yaw": -2.382708722432671,
        "pitch": 0.027185681214554336,
        "rotation": 0,
        "target": "5-waermenetzprojekte",
        isRoom: true
      },
      {
        "yaw": -2.658417818049621,
        "pitch": 0.05103808427402079,
        "rotation": 0,
        "target": "5-poster-flexbiomethane",
        isRoom: true
      },
      {
        "yaw": -3.0186238721994183,
        "pitch": 0.02970499774522395,
        "rotation": 0,
        "target": "8-grammersolar",
        isRoom: true
      },
      {
        "yaw": 2.7482528054101376,
        "pitch": 0.04803789423183069,
        "rotation": 0,
        "target": "8-omnicert",
        isRoom: true
      },
     /* {
        "yaw": -0.575988226705892,
        "pitch": -0.061089058997310985,
        "rotation": 0,
        "target": "7-biogas-anlage"
      },
      {
        "yaw": -1.595988226705892,
        "pitch": -0.061089058997310985,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -1.595988226705892,
        "pitch": 0.02141089058997310985,
        "rotation": 0,
        "target": "0-lobby"
      }*/
    ],
    "infoHotspots": [
      {
        yaw: 1.25,
        pitch: 0,
        translateX: "-530px",
        translateY: "-580px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>C.A.R.M.E.N e.V.</h2>",
      },{
        yaw: 1.25,
        pitch: 0,
        translateX: "-530px",
        translateY: "-450px",
        url: '#',
        params: {
          url: '#',
        },
        title: "<b>Larissa Auzinger</b><br /><small>Ich freue mich über Ihe E-Mail an la@carmen-ev.de!</small>",

        showInNavbar: true,
      },  
     {
      yaw: 1.15,
      pitch: 0, 
      translateX: "-190px",
      translateY: "520px",
      action: "openExternalLink",
      url: "https://www.carmen-ev.de",
      params: {
        url: "https://www.carmen-ev.de",
      },
      title: "www.carmen-ev.de",
      showInNavbar: true,
    },
    {
      yaw: 1.35,
      pitch: 0,
      translateX: "900px",
      translateY: "-500px",
      url: '#',
      isTitle: true,
      params: {
        url: '#',
      },
      title: "<h2>LEW Lechwerke</h2>",
    },{
      yaw: 1.35,
      pitch: 0,
      translateX: "900px",
      translateY: "-350px",
      url: '#',
      params: {
        url: '#',
      },
      title: "<b>Andreas Ruhland</b><br /><small>Ich freue mich über Ihre E-Mail an andreas.ruhland@lew.de!</small>",
      showInNavbar: true,
    },{
    yaw: 1.35,
    pitch: 0, 
    translateX: "800px",
    translateY: "590px",
    action: "openExternalLink",
    url: "https://www.lew.de",
    params: {
      url: "https://www.lew.de",
    },
    title: "www.lew.de",
    showInNavbar: true,
},{
      yaw: 1.35,
      pitch: 0,
      translateX: "500px",
      translateY: "-120px",
      action: "showImageBox",
      thumb: "/material/210616_lew-lechwerke/eeg/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/210616_lew-lechwerke/eeg/x_Seite_1.jpg",
          "/material/210616_lew-lechwerke/eeg/x_Seite_2.jpg",
          "/material/210616_lew-lechwerke/eeg/x_Seite_3.jpg",
          "/material/210616_lew-lechwerke/eeg/x_Seite_4.jpg", 
        ],
        pdf: "/material/210616_lew-lechwerke/eeg/EEG_Marktpraemienmodell.pdf",
      },
      title: "EEG Marktpraemienmodell",
      showInNavbar: true,
     }, {
      yaw: 1.3,
      pitch: 0,
      translateX: "900px",
      translateY: "-80px",
      type: "quer",
      action: "showImageBox",
      thumb: "/material/210616_lew-lechwerke/LEnergiezukunft.jpg",
      params: {
        type: "quer",
        slides: [
          "/material/210616_lew-lechwerke/LEnergiezukunft.jpg"
        ]
      },
      title: "LEnergiezukunft",
      showInNavbar: true,
     }, {
      yaw: 1.35,
      pitch: 0,
      translateX: "520px",
      translateY: "420px",
      action: "openExternalLink",
      url: "https://www.lew.de/fuer-unternehmen/energievermarktung#/",
      params: {
        url: "https://www.lew.de/fuer-unternehmen/energievermarktung#/",
      },
      title: "Energievermarktung für Unternehmen & Anlagenbetreiber",
      showInNavbar: true,
     },  

     {
      yaw: 1.2,
      pitch: 0,
      translateX: "-960px",
      translateY: "-320px",
      action: "showImageBox",
      thumb: "/material/210616_carmen/holzvergasungsanlagen/x_Seite_01.jpg",
      type: "halbsmall",
      params: {
        slides: [
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_01.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_03.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_04.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_05.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_07.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_08.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_09.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_10.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_11.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_12.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_13.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_14.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_15.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_16.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_17.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_18.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_19.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_20.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_21.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_22.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_23.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_24.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_25.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_26.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_27.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_28.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_29.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_30.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_31.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_32.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_33.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_34.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_35.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_36.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_37.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_38.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_39.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_40.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_41.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_42.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_43.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_44.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_45.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_46.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_47.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_48.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_49.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_50.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_51.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_52.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_53.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_54.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_55.jpg",
          "/material/210616_carmen/holzvergasungsanlagen/x_Seite_56.jpg"
        ],
        pdf: "/material/210616_carmen/holzvergasungsanlagen/Holzvergasungsanlagen.pdf",
      },
      title: "Holzvergasungsanlagen",
      showInNavbar: true,
     },      {
      yaw: 1.2,
      pitch: 0,
      translateX: "-700px",
      translateY: "-320px",
      action: "showImageBox",
      thumb: "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_01.jpg",
      type: "halbsmall",
      params: {
        slides: [
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_01.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_03.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_04.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_05.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_06.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_07.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_08.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_09.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_10.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_11.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_12.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_13.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_14.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_15.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_16.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_17.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_18.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_19.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_20.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_21.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_22.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_23.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_24.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_25.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_26.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_27.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_28.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_29.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_30.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_31.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_32.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_33.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_34.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_35.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_36.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_37.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_38.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_39.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_40.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_41.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_42.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_43.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_44.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_45.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_46.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_47.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_48.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_49.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_50.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_51.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_52.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_53.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_54.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_55.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_56.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_57.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_58.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_59.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_60.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_61.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_62.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_63.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_64.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_65.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_66.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_67.jpg",
          "/material/210616_carmen/kleinwindkraftanlagen/x_Seite_68.jpg",
        ],
        pdf: "/material/210616_carmen/kleinwindkraftanlagen/Kleinwindkraftanlagen.pdf",
      },
      title: "Kleinwindkraftanlagen",
      showInNavbar: true,
     }, {
      yaw: 1.2,
      pitch: 0,
      translateX: "-430px",
      translateY: "-320px",
      action: "showImageBox",
      type: "halbsmall",
      thumb: "/material/210616_carmen/photovoltaik/x_Seite_01.jpg",
      params: {
        slides: [
          "/material/210616_carmen/photovoltaik/x_Seite_01.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_02.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_03.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_04.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_05.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_06.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_07.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_08.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_09.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_10.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_11.jpg",
          "/material/210616_carmen/photovoltaik/x_Seite_12.jpg",
        ],
        pdf: "/material/210616_carmen/photovoltaik/Photovoltaikanlagen.pdf",
      },
      title: "Photovoltaikanlagen",
      showInNavbar: true,
     },{
      yaw: 1.2,
      pitch: 0,
      translateX: "-160px",
      translateY: "-320px",
      action: "showImageBox",
      type: "halbsmall",
      thumb: "/material/210616_carmen/waermeliefervertrag/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/210616_carmen/waermeliefervertrag/x_Seite_1.jpg",
          "/material/210616_carmen/waermeliefervertrag/x_Seite_2.jpg",
          "/material/210616_carmen/waermeliefervertrag/x_Seite_3.jpg",
          "/material/210616_carmen/waermeliefervertrag/x_Seite_4.jpg", 
        ],
        pdf: "/material/210616_carmen/waermeliefervertrag/Waermeliefervertrag.pdf",
      },
      title: "Wärmeliefervertrag",
      showInNavbar: true,
     },{
      yaw: 1.2,
      pitch: 0,
      translateX: "-960px",
      translateY: "80px",
      action: "showImageBox",
      type: "halbsmall",
      thumb: "/material/210616_carmen/energiewaelder/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/210616_carmen/energiewaelder/x_Seite_1.jpg",
          "/material/210616_carmen/energiewaelder/x_Seite_2.jpg",
          "/material/210616_carmen/energiewaelder/x_Seite_3.jpg",
          "/material/210616_carmen/energiewaelder/x_Seite_4.jpg", 
        ],
        pdf: "/material/210616_carmen/energiewaelder/energiewaelder.pdf",
      },
      title: "Energiewälder",
      showInNavbar: true,
     },{
      yaw: 1.2,
      pitch: 0,
      translateX: "-700px",
      translateY: "80px",
      action: "showImageBox",
      type: "halbsmall",
      thumb: "/material/210616_carmen/biomethan/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/210616_carmen/biomethan/x_Seite_1.jpg",
          "/material/210616_carmen/biomethan/x_Seite_2.jpg",
          "/material/210616_carmen/biomethan/x_Seite_3.jpg",
          "/material/210616_carmen/biomethan/x_Seite_4.jpg", 
          "/material/210616_carmen/biomethan/x_Seite_5.jpg",
          "/material/210616_carmen/biomethan/x_Seite_6.jpg",
          "/material/210616_carmen/biomethan/x_Seite_7.jpg",
          "/material/210616_carmen/biomethan/x_Seite_8.jpg", 
        ],
        pdf: "/material/210616_carmen/biomethan/Biomethan.pdf",
      },
      title: "Biomethan",
      showInNavbar: true,
     },{
      yaw: 1.2,
      pitch: 0,
      translateX: "-430px",
      translateY: "80px",
      action: "showImageBox",
      type: "halbsmall",
      thumb: "/material/210616_carmen/biogaswaerme/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/210616_carmen/biogaswaerme/x_Seite_1.jpg",
          "/material/210616_carmen/biogaswaerme/x_Seite_2.jpg",
          "/material/210616_carmen/biogaswaerme/x_Seite_3.jpg",
          "/material/210616_carmen/biogaswaerme/x_Seite_4.jpg", 
          "/material/210616_carmen/biogaswaerme/x_Seite_5.jpg",
          "/material/210616_carmen/biogaswaerme/x_Seite_6.jpg",
          "/material/210616_carmen/biogaswaerme/x_Seite_7.jpg",
          "/material/210616_carmen/biogaswaerme/x_Seite_8.jpg", 
        ],
        pdf: "/material/210616_carmen/biogaswaerme/biogaswaerme.pdf",
      },
      title: "Biogaswärme",
      showInNavbar: true,
     },
     {
      yaw: 1.2,
      pitch: 0,
      translateX: "-160px",
      translateY: "80px",        
      action: "showImageBox",
      type: "halbsmall",
      thumb: "/material/210616_carmen/soklappts/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/210616_carmen/soklappts/x_Seite_1.jpg",
          "/material/210616_carmen/soklappts/x_Seite_2.jpg",
          "/material/210616_carmen/soklappts/x_Seite_3.jpg",
          "/material/210616_carmen/soklappts/x_Seite_4.jpg",
        ],
        pdf: "/material/210616_carmen/soklappts/So-klappts-mit-dem-Stromliefervertrag.pdf",
      },
      title: "Stromliefervertrag",
      showInNavbar: true,
     }, {
      yaw: 1.2,
      pitch: 0,
      translateX: "-960px",
      translateY: "470px",
      action: "showImageBox",
      type: "scopesmall",
      thumb: "/material/210616_carmen/flyer/x_thumb.jpg",
      params: {
        type: "quer",
        slides: [
          "/material/210616_carmen/flyer/x1_Seite_1.jpg",
          "/material/210616_carmen/flyer/x1_Seite_2.jpg",
          "/material/210616_carmen/flyer/x2_Seite_1.jpg",
          "/material/210616_carmen/flyer/x2_Seite_2.jpg",
          "/material/210616_carmen/flyer/x3_Seite_1.jpg",
          "/material/210616_carmen/flyer/x3_Seite_2.jpg",
          "/material/210616_carmen/flyer/x4_Seite_1.jpg",
          "/material/210616_carmen/flyer/x4_Seite_2.jpg",
          "/material/210616_carmen/flyer/x5_Seite_1.jpg",
          "/material/210616_carmen/flyer/x5_Seite_2.jpg",
          "/material/210616_carmen/flyer/x6_Seite_1.jpg",
          "/material/210616_carmen/flyer/x6_Seite_2.jpg",
        ],
        pdf: "/material/210616_carmen/flyer/210616_carmen_Flyer.zip",
      },
      title: "Download Flyer-Package",
      showInNavbar: true,
     }
    ]
  },
  {
    "id": "10-schwaben-regenerativ",
    "parent": "0-atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "schwaben regenerativ",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 0.9773843811168277,
      "pitch": 0.07128827402688387,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": -0.021938115524804402,
        "pitch": 0.04026029494173855,
        "rotation": 0,
        "target": "11-ratiotherm",
        isRoom: true
      },
      {
        "yaw": -0.4333071961735797,
        "pitch": 0.037156349926005916,
        "rotation": 0,
        "target": "11-enerpipe",
        isRoom: true
      },
      {
        "yaw": -0.7959293109313847,
        "pitch": 0.030238437103335514,
        "rotation": 0,
        "target": "12-varmeco",
        isRoom: true
      },
      {
        "yaw": -1.5398410022486946,
        "pitch": 0.09339896063618625,
        "rotation": 0,
        "target": "1-empfang--anmeldung"
      },
     /* {
        "yaw": -0.9741766697693706,
        "pitch": -0.11850575693998167,
        "rotation": 0,
        "target": "7-biogas-anlage"
      }, */
      {
        "yaw": -1.939968442529107,
        "pitch": 0.10086295980569204,
        "rotation": 0,
        "target": "2--institut-fr-neue-energie-systeme-ines"
      },
     /* {
        "yaw": -2.220997904006584,
        "pitch": -0.0294691441415722,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -2.220997904006584,
        "pitch": -0.0994691441415722,
        "rotation": 0,
        "target": "0-lobby"
      },*/
      {
        "yaw": -2.3533523838133554,
        "pitch": 0.03444321418630558,
        "rotation": 0,
        "target": "3-studiengang",
        isRoom: true
      },
      {
        "yaw": -2.544941162568165,
        "pitch": 0.033682856903812564,
        "rotation": 0,
        "target": "4-poster-tav-control",
        isRoom: true
      },
      {
        "yaw": -3.0144635024097166,
        "pitch": 0.023341751880249006,
        "rotation": 0,
        "target": "5-poster-flexbiomethane",
        isRoom: true
      },
      {
        "yaw": 3.0286596711567917,
        "pitch": 0.008821788260016561,
        "rotation": 0,
        "target": "5-waermenetzprojekte",
        isRoom: true
      },
      {
        "yaw": 2.7836293029596195,
        "pitch": 0.03479575618177222,
        "rotation": 0,
        "target": "8-grammersolar",
        isRoom: true
      },
      {
        "yaw": 2.4852611400630114,
        "pitch": 0.03163264592505577,
        "rotation": 0,
        "target": "8-omnicert",
        isRoom: true
      },
      {
        "yaw": 2.2225080831782345,
        "pitch": 0.028172038799858967,
        "rotation": 0,
        "target": "9-carmen",
        isRoom: true
      },
      {
        "yaw": 1.9711707855608456,
        "pitch": 0.03850744241941939,
        "rotation": 0,
        "target": "9-lew-lechwerke",
        isRoom: true
      }
    ],
    infoHotspots: [
      {
        yaw: 1,
        pitch: 0,
        translateX: "0px",
        translateY: "-500px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>schwaben regenerativ</h2>",
      },
    {
      yaw: 1,
      pitch: 0,
      translateX: "0px",
      translateY: "-350px",
      url: '#',
      params: {
        url: '#',
      },
      title: "<b>Peter Göb</b><br /><small>Ich freue mich über Ihre E-Mail an peter.goeb@schwaben-regenerativ.de!</small>",
      showInNavbar: true,
    },
    {  
      yaw: 1,
      pitch: 0, 
      translateX: "-230px",
      translateY: "650px",
      action: "openExternalLink",
      url: "https://www.schwaben-regenerativ.de",
      params: {
        url: "https://www.schwaben-regenerativ.de",
      },
      title: "www.schwaben-regenerativ.de",
      showInNavbar: true,
  },
  {
    yaw: 1,
    pitch: 0,
    translateX: "-350px",
    translateY: "-100px",
    action: "showImageBox",
    type: "halbquer",
    thumb: "/material/210616_schwabenregenerativ/bioerdgas/x_Seite_1.jpg",
    params: {
      type: "quer",
      slides: [
        "/material/210616_schwabenregenerativ/bioerdgas/x_Seite_1.jpg"
      ],
      pdf: "/material/210616_schwabenregenerativ/bioerdgas/sr_so-entsteht-bio-erdgas.pdf",
    },
    title: "So entsteht Bio-Erdgas",
    showInNavbar: true,
   },{
    yaw: 1,
    pitch: 0,
    translateX: "50px",
    translateY: "-100px",
    action: "showImageBox",
    type: "halbquer",
    thumb: "/material/210616_schwabenregenerativ/powertogas/x_Seite_1.jpg",
    params: {
      slides: [
        "/material/210616_schwabenregenerativ/powertogas/x_Seite_1.jpg"
      ],
      pdf: "/material/210616_schwabenregenerativ/powertogas/sr_grafik_power-to-gas.pdf",
    },
    title: "Funktionsweise Power-to-Gas",
    showInNavbar: true,
   },{
    yaw: 1,
    pitch: 0,
    translateX: "-150px",
    translateY: "250px",
    action: "showImageBox",
    type: "halbquer",
    thumb: "/material/210616_schwabenregenerativ/wegdesholzes/x_Seite_1.jpg",
    params: {
      type: "quer",
      slides: [
        "/material/210616_schwabenregenerativ/wegdesholzes/x_Seite_1.jpg"
      ],
      pdf: "/material/210616_schwabenregenerativ/wegdesholzes/sr_grafik_weg-des-holzes.pdf",
    },
    title: "Der Weg des Holzes",
    showInNavbar: true,
   }      
    ],
  },
  {
    "id": "11-enerpipe",
    "parent": "0-atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "ENERPIPE",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 1.01,
      "pitch": 0.08,
      "fov": 1
    },
    "linkHotspots": [
      {
        "yaw": -0.1678461408693211,
        "pitch": 0.03956482333043354,
        "rotation": 0,
        "target": "12-varmeco",
        isRoom: true
      },
      {
        "yaw": -1.2660808927721241,
        "pitch": 0.12159240903912227,
        "rotation": 0,
        "target": "1-empfang--anmeldung"
      },
      {
        "yaw": -1.7078250022280344,
        "pitch": 0.05604771969652482,
        "rotation": 0,
        "target": "2--institut-fr-neue-energie-systeme-ines"
      },
      {
        "yaw": -2.173226992155943,
        "pitch": 0.008710595249766158,
        "rotation": 0,
        "target": "3-studiengang",
        isRoom: true
      },
      {
        "yaw": -2.3825222744218204,
        "pitch": 0.019620747096901,
        "rotation": 0,
        "target": "4-poster-tav-control",
        isRoom: true
      },
      {
        "yaw": -2.725222744218204,
        "pitch": 0.019620747096901,
        "rotation": 0,
        "target": "5-poster-flexbiomethane",
        isRoom: true
      },
      {
        "yaw": 3.369715289841877,
        "pitch": 0.030985467117520926,
        "rotation": 0,
        "target": "5-waermenetzprojekte",
        isRoom: true
      },
      {
        "yaw": 3.1885282753752477,
        "pitch": 0.03663523336487273,
        "rotation": 0,
        "target": "8-grammersolar",
        isRoom: true
      },
      {
        "yaw": 2.978897718576267,
        "pitch": 0.02446100889060432,
        "rotation": 0,
        "target": "8-omnicert",
        isRoom: true
      },
      {
        "yaw": 2.82787752865381,
        "pitch": 0.0434823267621951,
        "rotation": 0,
        "target": "9-carmen",
        isRoom: true
      },
      {
        "yaw": 2.7346591826804592,
        "pitch": 0.06823301648167757,
        "rotation": 0,
        "target": "9-lew-lechwerke",
        isRoom: true
      },
      {
        "yaw": 2.5454809435737715,
        "pitch": 0.08884299467592349,
        "rotation": 0,
        "target": "10-schwaben-regenerativ",
        isRoom: true
      },
      {
        "yaw": 2.0454809435737715,
        "pitch": 0.08884299467592349,
        "rotation": 0,
        "target": "11-ratiotherm",
        isRoom: true
      }
    ],
    "infoHotspots": [{
      yaw: 1,
      pitch: 0, 
      translateX: "-70px",
      translateY: "660px",
      action: "openExternalLink",
      url: "https://www.enerpipe.de",
      params: {
        url: "https://www.enerpipe.de",
      },
      title: "www.enerpipe.de",
      showInNavbar: true,
    },{
      yaw: 1,
      pitch: 0,
      translateX: "50px",
      translateY: "-500px",
      url: '#',
      isTitle: true,
      params: {
        url: '#',
      },
      title: "<h2>ENERPIPE GmbH</h2>",
    },{
      yaw: 1,
      pitch: 0,
      translateX: "50px",
      translateY: "-350px",
      url: '#',
      params: {
        url: '#',
      },
      title: "<b>Markus Euring</b><br /><small>Ich freue mich über Ihe E-Mail an markus.euring@enerpipe.de!</small>",
      showInNavbar: true,
    }, {
        yaw: 1,
        pitch: 0,
        translateX: "-390px",
        translateY: "230px",
        action: "showImageBox",
        thumb: "/material/210616_enerpipe/heizungsjournal/x_Seite_1.jpg",
        params: {
          slides: [
            "/material/210616_enerpipe/heizungsjournal/x_Seite_1.jpg",
            "/material/210616_enerpipe/heizungsjournal/x_Seite_2.jpg",
            "/material/210616_enerpipe/heizungsjournal/x_Seite_3.jpg",
            "/material/210616_enerpipe/heizungsjournal/x_Seite_4.jpg",
            "/material/210616_enerpipe/heizungsjournal/x_Seite_5.jpg",
            "/material/210616_enerpipe/heizungsjournal/x_Seite_6.jpg",
          ],
          pdf: "/material/210616_enerpipe/heizungsjournal/Pfofeld_Heizungsjournal.pdf",
        },
        title: "Heizungsjournal",
        showInNavbar: true,
       },
        {
          yaw: 1,
          pitch: 0,
          translateX: "-390px",
          translateY: "-200px",
          action: "showImageBox",
          thumb: "/material/210616_enerpipe/smartenahwaerme/x_Seite_1.jpg",
          params: {
            slides: [
              "/material/210616_enerpipe/smartenahwaerme/x_Seite_1.jpg",
              "/material/210616_enerpipe/smartenahwaerme/x_Seite_2.jpg",
              "/material/210616_enerpipe/smartenahwaerme/x_Seite_3.jpg",
              "/material/210616_enerpipe/smartenahwaerme/x_Seite_4.jpg"
            ],
            pdf: "/material/210616_enerpipe/smartenahwaerme/Smarte_Nahwaerme.pdf",
          },
          title: "Smarte Nahwärme",
          showInNavbar: true,
        },
        {
          yaw: 1,
          pitch: 0,
          translateX: "-70px",
          translateY: "-200px",
          action: "showImageBox",
          thumb: "/material/210616_enerpipe/bestpractice/x_Seite_01.jpg",
          params: {
            slides: [
              "/material/210616_enerpipe/bestpractice/x_Seite_01.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_03.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_04.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_05.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_07.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_08.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_09.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_10.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_11.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_12.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_13.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_14.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_15.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_16.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_17.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_18.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_19.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_20.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_21.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_22.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_23.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_24.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_25.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_26.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_27.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_28.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_29.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_30.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_31.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_32.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_33.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_34.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_35.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_36.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_37.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_38.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_39.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_40.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_41.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_42.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_43.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_44.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_45.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_46.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_47.jpg",
              "/material/210616_enerpipe/bestpractice/x_Seite_48.jpg"
            ],
            pdf:  "/material/210616_enerpipe/bestpractice/bestpractice.pdf",
          },
          title: "16 Nahwärme-Projekte",
          showInNavbar: true,
        }, {
          yaw: 1,
          pitch: 0,
          translateX: "250px",
          translateY: "-200px",
          action: "showImageBox",
          thumb: "/material/210616_enerpipe/waermenetzplanung/x_Seite_1.jpg",
          params: {
            slides: [
              "/material/210616_enerpipe/waermenetzplanung/x_Seite_1.jpg",
              "/material/210616_enerpipe/waermenetzplanung/x_Seite_2.jpg",
              "/material/210616_enerpipe/waermenetzplanung/x_Seite_3.jpg",
              "/material/210616_enerpipe/waermenetzplanung/x_Seite_4.jpg",
              "/material/210616_enerpipe/waermenetzplanung/x_Seite_5.jpg",
              "/material/210616_enerpipe/waermenetzplanung/x_Seite_6.jpg"
            ],
            pdf: "/material/210616_enerpipe/waermenetzplanung/Waermenetzplanung.pdf",
          },
          title: "Wärmenetzplanung 4.0",
          showInNavbar: true,
        }, {
          yaw: 1,
          pitch: 0,
          translateX: "0px",
          translateY: "270px",
          action: "showImageBox",
          thumb: "/material/210616_enerpipe/produktuebersicht/x_Seite_1.jpg",
          type: "quer",
          params: {
            type: "quer",
            slides: [
              "/material/210616_enerpipe/produktuebersicht/x_Seite_1.jpg"
            ],
            pdf: "/material/210616_enerpipe/produktuebersicht/ENERPIPE_Uebersicht-Produkte_2021.pdf",
          },
          title: "Produktübersicht 2021",
          showInNavbar: true,
        }
    ]
  },  {
    "id": "11-ratiotherm",
    "parent": "0-atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "ratiotherm",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 1,
      "pitch": 0.1,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      {
        "yaw": -0.7278461408693211,
        "pitch": 0.03956482333043354,
        "rotation": 0,
        "target": "12-varmeco",
        isRoom: true
      },
      {
        "yaw": -1.5660808927721241,
        "pitch": 0.12159240903912227,
        "rotation": 0,
        "target": "1-empfang--anmeldung"
      },
      {
        "yaw": -1.9578250022280344,
        "pitch": 0.10604771969652482,
        "rotation": 0,
        "target": "2--institut-fr-neue-energie-systeme-ines"
      },
      {
        "yaw": -2.423226992155943,
        "pitch": 0.028710595249766158,
        "rotation": 0,
        "target": "3-studiengang",
        isRoom: true
      },
      {
        "yaw": -2.6225222744218204,
        "pitch": 0.039620747096901,
        "rotation": 0,
        "target": "4-poster-tav-control",
        isRoom: true
      },
      {
        "yaw": -3.0136979629991387,
        "pitch": 0.030498288763514125,
        "rotation": 0,
        "target": "5-poster-flexbiomethane",
        isRoom: true
      },
      {
        "yaw": 3.069715289841877,
        "pitch": 0.030985467117520926,
        "rotation": 0,
        "target": "5-waermenetzprojekte",
        isRoom: true
      },
      {
        "yaw": 2.8585282753752477,
        "pitch": 0.03663523336487273,
        "rotation": 0,
        "target": "8-grammersolar",
        isRoom: true
      },
      {
        "yaw": 2.628897718576267,
        "pitch": 0.02446100889060432,
        "rotation": 0,
        "target": "8-omnicert",
        isRoom: true
      },
      {
        "yaw": 2.456787752865381,
        "pitch": 0.0434823267621951,
        "rotation": 0,
        "target": "9-carmen",
        isRoom: true
      },
      {
        "yaw": 2.346591826804592,
        "pitch": 0.08823301648167757,
        "rotation": 0,
        "target": "9-lew-lechwerke",
        isRoom: true
      },
      {
        "yaw": 2.0454809435737715,
        "pitch": 0.08884299467592349,
        "rotation": 0,
        "target": "10-schwaben-regenerativ",
        isRoom: true
      },
      {
        "yaw": -0.1278461408693211,
        "pitch": 0.03956482333043354,
        "rotation": 0,
        "target": "11-enerpipe",
        isRoom: true
      }
    ],
    "infoHotspots": [ 
      {
        yaw: 1,
        pitch: 0,
        translateX: "50px",
        translateY: "-500px",
        url: '#',
        isTitle: true,
        params: {
          url: '#',
        },
        title: "<h2>ratiotherm GmbH & Co. KG</h2>",
      },{
        yaw: 1,
        pitch: 0,
        translateX: "50px",
        translateY: "-350px",
        url: '#',
        params: {
          url: '#',
        },
        title: "<b>Michael Westermaier</b><br /><small>Ich freue mich über Ihre E-Mail an m.westermaier@ratiotherm.de!</small>",
        showInNavbar: true,
      },{
      yaw: 1,
      pitch: 0, 
      translateX: "-200px",
      translateY: "650px",
      action: "openExternalLink",
      url: "https://ratiotherm.de/kalte-nahwaerme/",
      params: {
        url: "https://ratiotherm.de/kalte-nahwaerme/",
      },
      title: "ratiotherm.de/kalte-nahwaerme",
      showInNavbar: true,
  },
  {
        yaw: 1,
        pitch: 0,
        translateX: "-210px",
        translateY: "220px",
        action: "showImageBox",
        thumb: "/material/210616_ratiotherm/slides/x_Seite_1.jpg",
        type: "quer",
        params: {
          type: "quer",
          slides: [
            "/material/210616_ratiotherm/slides/x_Seite_1.jpg",
            "/material/210616_ratiotherm/slides/x_Seite_2.jpg",
            "/material/210616_ratiotherm/slides/x_Seite_3.jpg",
            "/material/210616_ratiotherm/slides/x_Seite_4.jpg",
            "/material/210616_ratiotherm/slides/x_Seite_5.jpg",
            "/material/210616_ratiotherm/slides/x_Seite_6.jpg",
            "/material/210616_ratiotherm/slides/x_Seite_7.jpg",
            "/material/210616_ratiotherm/slides/x_Seite_8.jpg",
          ],
          pdf: "/material/210616_ratiotherm/slides/Slides_Messestand_ratiotherm.pdf",
        },
        title: "LEnZ Fachtagung",
        showInNavbar: true,
       },      {
        yaw: 1,
        pitch: 0,
        translateX: "-420px",
        translateY: "-220px",
        action: "showImageBox",
        thumb: "/material/210616_ratiotherm/planungsleitfaden/x_Seite_001.jpg",
        params: {
          slides: [
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_001.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_003.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_004.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_005.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_006.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_007.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_008.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_009.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_010.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_011.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_012.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_013.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_014.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_015.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_016.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_017.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_018.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_019.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_020.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_021.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_022.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_023.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_024.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_025.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_026.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_027.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_028.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_029.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_030.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_031.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_032.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_033.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_034.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_035.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_036.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_037.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_038.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_039.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_040.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_041.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_042.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_043.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_044.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_045.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_046.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_047.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_048.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_049.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_050.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_051.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_052.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_053.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_054.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_055.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_056.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_057.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_058.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_059.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_060.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_061.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_062.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_063.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_064.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_065.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_066.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_067.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_068.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_069.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_070.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_071.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_072.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_073.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_074.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_075.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_076.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_077.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_078.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_079.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_080.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_081.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_082.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_083.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_084.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_085.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_086.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_087.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_088.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_089.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_090.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_091.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_092.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_093.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_094.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_095.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_096.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_097.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_098.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_099.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_100.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_101.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_102.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_103.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_104.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_105.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_106.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_107.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_108.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_109.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_110.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_111.jpg",
            "/material/210616_ratiotherm/planungsleitfaden/x_Seite_112.jpg"
          ],
          pdf: "/material/210616_ratiotherm/planungsleitfaden/Planungsleitfaden_D_WEB.pdf",
        },
        title: "Planungsleitfaden",
        showInNavbar: true,
       }, {
        yaw: 1,
        pitch: 0,
        translateX: "-100px",
        translateY: "-220px",
        action: "showImageBox",
        thumb: "/material/210616_ratiotherm/wpgrid/x_Seite_1.jpg",
        params: {
          slides: [
            "/material/210616_ratiotherm/wpgrid/x_Seite_1.jpg",
            "/material/210616_ratiotherm/wpgrid/x_Seite_2.jpg",
            "/material/210616_ratiotherm/wpgrid/x_Seite_3.jpg",
            "/material/210616_ratiotherm/wpgrid/x_Seite_4.jpg",
            "/material/210616_ratiotherm/wpgrid/x_Seite_5.jpg",
            "/material/210616_ratiotherm/wpgrid/x_Seite_6.jpg",
          ],
          pdf:  "/material/210616_ratiotherm/wpgrid/Flyer_Max-Grid.pdf",
        },
        title: "WP Grid",
        showInNavbar: true,
       },{
        yaw: 1,
        pitch: 0,
        translateX: "230px",
        translateY: "-220px",
        action: "showImageBox",
        thumb: "/material/210616_ratiotherm/wpmaxair/x_Seite_1.jpg",
        params: {
          slides: [
            "/material/210616_ratiotherm/wpmaxair/x_Seite_1.jpg",
            "/material/210616_ratiotherm/wpmaxair/x_Seite_2.jpg",
            "/material/210616_ratiotherm/wpmaxair/x_Seite_3.jpg",
            "/material/210616_ratiotherm/wpmaxair/x_Seite_4.jpg", 
          ],
          pdf: "/material/210616_ratiotherm/wpmaxair/WEB_WP_Max-Air_4.pdf",
        },
        title: "WP Max-Air",
        showInNavbar: true,
       }
    ]
  },
  {
    "id": "12-varmeco",
    "parent": "0-atrium",
    "inSubmenu": true,
    hasChildren: true,
    hasMenu: true,
    "name": "varmeco",
    "levels": [
      {
        "tileSize": 256,
        "size": 256,
        "fallbackOnly": true
      },
      {
        "tileSize": 512,
        "size": 512
      },
      {
        "tileSize": 512,
        "size": 1024
      },
      {
        "tileSize": 512,
        "size": 2048
      }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
      "yaw": 0.9800695030429889,
      "pitch": 0.03838599370677187,
      "fov": 1.431249193924321
    },
    "linkHotspots": [
      /* {
        "yaw": -0.012140218994234786,
        "pitch": 0.001892371286086103,
        "rotation": 0,
        "target": "7-biogas-anlage"
      },*/
      {
        "yaw": -0.7150556640786228,
        "pitch": 0.14093748786234706,
        "rotation": 0,
        "target": "1-empfang--anmeldung"
      },
      {
        "yaw": -1.1586848623756154,
        "pitch": 0.11006907481636574,
        "rotation": 0,
        "target": "2--institut-fr-neue-energie-systeme-ines"
      },
      /* {
        "yaw": -1.3732730822116075,
        "pitch": -0.0016992466708209975,
        "rotation": 0,
        "target": "0-lecture-hall"
      },
      {
        "yaw": -1.3732730822116075,
        "pitch": -0.0816992466708209975,
        "rotation": 0,
        "target": "0-lobby"
      },*/
      {
        "yaw": -1.6715472827345899,
        "pitch": 0.021286537461854493,
        "rotation": 0,
        "target": "3-studiengang",
        isRoom: true
      },
      {
        "yaw": -1.8816066332182046,
        "pitch": 0.021242268054539437,
        "rotation": 0,
        "target": "4-poster-tav-control",
        isRoom: true
      },
      {
        "yaw": -2.2071971788050746,
        "pitch": 0.021945132669914713,
        "rotation": 0,
        "target": "5-poster-flexbiomethane",
        isRoom: true
      },
      {
        "yaw": -2.377084399868348,
        "pitch": 0.02672588043862234,
        "rotation": 0,
        "target": "5-waermenetzprojekte",
        isRoom: true
      },
      /* {
        "yaw": -2.067329459374667,
        "pitch": -0.048041464028313996,
        "rotation": 0,
        "target": "6-wrmetechnischer-prfstand"
      }, */
      {
        "yaw": -2.5522923620638203,
        "pitch": 0.02971533368138779,
        "rotation": 0,
        "target": "8-grammersolar",
        isRoom: true
      },
      {
        "yaw": -2.731837280243001,
        "pitch": 0.0264091056419975,
        "rotation": 0,
        "target": "8-omnicert",
        isRoom: true
      },
      {
        "yaw": -2.8717623562427015,
        "pitch": 0.0171685913751314,
        "rotation": 0,
        "target": "9-carmen",
        isRoom: true
      },
      {
        "yaw": -2.9647631948460447,
        "pitch": 0.06312552856393026,
        "rotation": 0,
        "target": "9-lew-lechwerke",
        isRoom: true
      },
      {
        "yaw": -3.121515691527007,
        "pitch": 0.019450556926837095,
        "rotation": 0,
        "target": "10-schwaben-regenerativ",
        isRoom: true
      },
      {
        "yaw": 2.8492967071652817,
        "pitch": 0.06631282597668786,
        "rotation": 0,
        "target": "11-ratiotherm",
        isRoom: true
      },
      {
        "yaw": 2.3748050637531914,
        "pitch": 0.10138371917419953,
        "rotation": 0,
        "target": "11-enerpipe",
        isRoom: true
      }
    ],
    "infoHotspots": [{
      yaw: 1,
      pitch: 0, 
      translateX: "-300px",
      translateY: "660px",
      action: "openExternalLink",
      url: "https://www.varmeco.de",
      params: {
        url: "https://www.varmeco.de",
      },
      title: "www.varmeco.de",
      showInNavbar: true,
    },{
      yaw: 1,
      pitch: 0, 
      translateX: "50px",
      translateY: "660px",
      action: "openExternalLink",
      url: "https://exergiemaschine.com/",
      params: {
        url: "https://exergiemaschine.com/",
      },
      title: "www.exergiemaschine.de",
      showInNavbar: true,
    },{
      yaw: 1,
      pitch: 0,
      translateX: "50px",
      translateY: "-550px",
      url: '#',
      isTitle: true,
      params: {
        url: '#',
      },
      title: "<h2>varmeco GmbH & Co KG</h2>",
    },{
      yaw: 1,
      pitch: 0,
      translateX: "50px",
      translateY: "-410px",
      url: '#',
      params: {
        url: '#',
      },
      title: "<b>Dr.-Ing. Andreas Wimmer</b><br /><small>Ich freue mich über Ihre E-Mail an andreas.wimmer@varmeco.de!</small>",
      showInNavbar: true,
    },{
      yaw: 1,
      pitch: 0,
      translateX: "-250px",
      translateY: "-200px",
      action: "showImageBox",
      thumb: "/material/210616_varmeco/imagebroschuere/x_Seite_01.jpg",
      params: {
        slides: [
          "/material/210616_varmeco/imagebroschuere/x_Seite_01.jpg",
          "/material/210616_varmeco/imagebroschuere/x_Seite_02.jpg",
          "/material/210616_varmeco/imagebroschuere/x_Seite_03.jpg",
          "/material/210616_varmeco/imagebroschuere/x_Seite_04.jpg",
          "/material/210616_varmeco/imagebroschuere/x_Seite_05.jpg",
          "/material/210616_varmeco/imagebroschuere/x_Seite_06.jpg",
          "/material/210616_varmeco/imagebroschuere/x_Seite_07.jpg",
          "/material/210616_varmeco/imagebroschuere/x_Seite_08.jpg",
          "/material/210616_varmeco/imagebroschuere/x_Seite_09.jpg",
          "/material/210616_varmeco/imagebroschuere/x_Seite_10.jpg",
          "/material/210616_varmeco/imagebroschuere/x_Seite_11.jpg",
          "/material/210616_varmeco/imagebroschuere/x_Seite_12.jpg",
          "/material/210616_varmeco/imagebroschuere/x_Seite_13.jpg",
          "/material/210616_varmeco/imagebroschuere/x_Seite_14.jpg",
          "/material/210616_varmeco/imagebroschuere/x_Seite_15.jpg",
          "/material/210616_varmeco/imagebroschuere/x_Seite_16.jpg"
        ],
        pdf: "/material/210616_varmeco/imagebroschuere/varmeco.pdf",
      },
      title: "Imagebroschüre",
      showInNavbar: true,
     },{
      yaw: 1,
      pitch: 0,
      translateX: "80px",
      translateY: "-200px",
      action: "showImageBox",
      thumb: "/material/210616_varmeco/exergiemaschine/x_Seite_1.jpg",
      params: {
        slides: [
          "/material/210616_varmeco/exergiemaschine/x_Seite_1.jpg",
          "/material/210616_varmeco/exergiemaschine/x_Seite_2.jpg",
          "/material/210616_varmeco/exergiemaschine/x_Seite_3.jpg",
          "/material/210616_varmeco/exergiemaschine/x_Seite_4.jpg",
          "/material/210616_varmeco/exergiemaschine/x_Seite_5.jpg",
          "/material/210616_varmeco/exergiemaschine/x_Seite_6.jpg",
          "/material/210616_varmeco/exergiemaschine/x_Seite_7.jpg",
          "/material/210616_varmeco/exergiemaschine/x_Seite_8.jpg"
        ],
        pdf: "/material/210616_varmeco/exergiemaschine/produktinfo_exergiemaschine.pdf",
      },
      title: "eXergiemaschine",
      showInNavbar: true,
     },{
      yaw: 1,
      pitch: 0,          
      type: "quadrat",
      translateX: "-80px",
      translateY: "250px",
      action: "showImageBox",
      thumb: "/material/210616_varmeco/video/thumb.jpg",
      params: {
          video: "/material/210616_varmeco/video/video.mp4",
          type: "quer",
      },
      title: "Thermografieaufnahme",
      showInNavbar: true,
    },
    ]
  },
];
export default ATRIUM; 
