const NAV_DATA = {
  firstLevelSubmenu: {
    label: "Stellwände",
  },
  navbarLeft: [
    {
      key: "back",
      label: "Zurück zur Empfangshalle",
      icon: "arrow-back-256",
      action: "goBack",
    },
  ],
  navbarRight: [
    {
      key: "lecture",
      label: "Zur Hauptbühne",
      action: "openLink",
      sceneId: "https://app.meet.teamgeist.com/v/fachtagung_lenz_2022/live/mainstage"
    },
  ],
};

export default NAV_DATA;
