import React, { useState, useEffect } from "react";
import NavOptions from "./NavOptions";
import "./Navbar.scss";
import APP_DATA from "../../data/data";
import NAV_DATA from "../../data/navigation";

const Navbar = ({ room }) => {
  const [navbarCenter, setNavbarCenter] = useState([]);
  const [navbarSubmenu, setNavbarSubmenu] = useState([]);
  const [hasMenu, setHasMenu] = useState([]);

  useEffect(() => {
    const currentRoom = APP_DATA.scenes.find((scene) => scene.id === room);
    
    if (currentRoom) {
        // @ts-ignore
        setHasMenu(currentRoom.hasMenu);
      // @ts-ignore
      if (currentRoom.hasChildren) {
        let childScenes = APP_DATA.scenes.filter(
          // @ts-ignore
          ({ parent, inSubmenu = false, hideFromMenu = false }) => parent === room && !inSubmenu && !hideFromMenu
        );
        const _navbarSubmenu = APP_DATA.scenes.filter(
          // @ts-ignore
          ({ parent, inSubmenu = false }) => parent === room && inSubmenu
        );

        setNavbarSubmenu(_navbarSubmenu);
        if (_navbarSubmenu.length) {
          setNavbarCenter([
            ...childScenes.map((scene) => ({
              label: scene.name,
              action: "sceneLink",
              sceneId: scene.id,
            })),
            {
              sceneId: "submenu",
              label: NAV_DATA.firstLevelSubmenu.label,
              icon: "",
              action: "openSubmenu",
            }
          ]);
        } else {
          setNavbarCenter(
            childScenes.map((scene) => ({
              label: scene.name,
              action: "sceneLink",
              sceneId: scene.id,
            }))
          );
        }
      } else {
        const infoActions = currentRoom.infoHotspots
          .filter(({ showInNavbar }) => showInNavbar)
          .map(({ title, action, params }) => ({
            label: title,
            action,
            params,
          }));

        const siblingScenes = APP_DATA.scenes.filter(
          // @ts-ignore

          (scene) => scene.parent && scene.parent === currentRoom.parent
        );
        setNavbarCenter([
          ...siblingScenes.map((scene) => ({
            label: scene.name,
            action: "sceneLink",
            sceneId: scene.id,
          })),
          ...infoActions,
        ]);
      }
    }
  }, [room]);

  console.log("room", room);
  console.log("menu", hasMenu);
  
  return (
    <div className={room}>
    {hasMenu && (
      <nav className={(room === '0-atrium') ? "atrium navbar" : "navbar"}>
      
          <NavOptions navOptions={NAV_DATA.navbarLeft} position="nav-left" />
          <NavOptions
            navOptions={navbarCenter}
            submenuOptions={navbarSubmenu}
            room={room} 
            position="nav-center"
          />
     
       {room !== "0-lecture-hall" && (
          <NavOptions navOptions={NAV_DATA.navbarRight} position="nav-right" />
        )}
    </nav>
    )}
    </div>
  );
};

export default Navbar;
