const LECTURE_HALL = [ {
  id: "0-lecture-hall",
  name: "Zur Hauptbühne",
  parent: "0-atrium",
  hasChildren: true,
  inSubmenu: false,
  hideFromMenu: true,
  levels: [
    {
      tileSize: 256,
      size: 256,
      fallbackOnly: true,
    },
    {
      tileSize: 512,
      size: 512,
    },
    {
      tileSize: 512,
      size: 1024,
    },
    {
      tileSize: 512,
      size: 2048,
    },
  ],
  faceSize: 1875,
  initialViewParameters: {
    pitch: 0,
    yaw: 0,
    fov: 1.5707963267948966,
  },
  linkHotspots: [],
  embeddedHotspots: [

  ],
  infoHotspots: [

  ],

 
}];
export default LECTURE_HALL;
